import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  // baseURL: any = 'https://api.vedangshastra.com'
  baseURL: any = environment.apiurl
  
  selectedConsultancy = new BehaviorSubject<{ data: any, id: any }>({ data: null, id: null });
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': 'true',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  constructor(private http: HttpClient) { }

  postSignupForm(formData: any): Observable<any> {
    return this.http.post(this.baseURL + `/api/v1/user/signup`, formData, this.httpOptions);
  }
}
