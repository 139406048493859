<app-header></app-header>
<div class="main-content">
    <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
        <div class="container breadcrumb-container">
            <ol class="breadcrumb">
                <li class="breadcrum-title">My Booking</li>
                <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img src="assets/icons/home-icon.png"
                            class="" alt=""> </a></li>
                <li class="breadcrumb-item active" aria-current="page">My Booking</li>
            </ol>
        </div>
    </nav>
    <section>
        <div class="container">
            <div class="table-responsive">
                <table class="table text-center table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Booking Date</th>
                            <th scope="col">Pooja Date</th>
                            <th scope="col">Pooja Name</th>
                            <th scope="col">Total Amount(INR)</th>
                            <th scope="col">Paid Amount</th>
                            <th scope="col">Remaining Amount</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let booking of bookingDetails">
                            <td>{{ booking.booking_date | date: 'MMM dd, yyyy' }}</td>
                            <td>{{ booking.pooja_date | date: 'MMM dd, yyyy' }}</td>
                            <td>{{ booking.pooja_type }}</td>
                            <td>{{ booking.package_amount | currency: 'INR' }}</td>
                            <td>{{booking.paid_amount | currency: 'INR'}}</td>
                            <td>{{booking.remaining_amount | currency: 'INR'}}</td>
                            <td>
                                <span [ngClass]="{
                                  'badge cancel': booking.status === 'Cancelled',
                                  'badge pending': booking.status === 'Booked',
                                  'badge completed': booking.status === 'completed',
                                }">
                                    {{ booking.status }}
                                </span>
                            </td>
                            <td>
                                <ng-container *ngIf="booking.status === 'Booked'">
                                    <img src="assets/images/flat-cross.png" class="cross-btn"
                                        (click)="cancelBooking(booking)">
                                </ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>