<div class="page-container">
    <app-header></app-header>
    <div class="main-content">
        <section class="banner-section">
            <owl-carousel-o [options]="customHomeSliderOptions">

                <ng-container>
                    <ng-template carouselSlide>
                        <div class="owl-item">
                            <!-- <div class="si-img"
                                style="background-image: url(assets/images/MicrosoftTeams-image1.png); cursor: pointer;"
                                routerLink="/list-of-pooja">
                               
                            </div> -->
                            <img src=".././../../assets/images/MicrosoftTeams-image1.png" alt="ganpati idol" style="cursor: pointer;" routerLink="/list-of-pooja">
                        </div>
                    </ng-template>
                </ng-container>

            </owl-carousel-o>
        </section>
        <section class="select-pooja-module popularPoojaSlider">
            <div class="container" >
                <h3 class="section-heading mb-4">POPULAR POOJA</h3>
                <div class="sp-container">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let name of allPooja">
                            <ng-template carouselSlide>
                                <div class="poojaCard">
                                    <div class="pooja-card">
                                        <div class="img" (click)="bookNow(name.id.toString())">
                                            <img [src]="getImageUrl(name.imagepath)" alt=""  >
                                            <!-- <img [src]="getImageUrl(name.imagepath)" alt="" (click)="visitPooja(name)"> -->
                                        </div>
                                    </div>
                                    <div class="cardFooter mt-2">
                                        <h3 class="title mb-3" (click)="bookNow(name.id.toString())">{{
                                            name.name }}</h3>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h2 class="price">₹ {{ name.booking_amount }}</h2>
                                            <button (click)="bookNow(name.id.toString())">Book Now</button>
                                        </div>
                                        <ul>
                                        </ul>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </section>


        <section class="hw-to-bk-section">
            <div class="container">
                <h3 class="section-heading">HOW TO BOOK</h3>
                <p class="heading-content">Help Yajman to know the process of getting books for <br> pooja and
                    conducting the ritual at muhurt. </p>
                <div class="custom-row">

                    <div class="custom-col">
                        <div class="icon-container">
                            <div class="icon-background">
                                <img src="assets/images/select-pooja.png" width="100" height="100" alt="mobile icon">
                            </div>
                            <h6>Select Pooja</h6>
                        </div>
                    </div>
                    <div class="custom-col">
                        <div class="icon-container">
                            <div class="icon-background">
                                <img src="assets/images/check-muhurt.png" width="100" height="100" alt="muhurt icon">
                            </div>
                            <h6>Check Muhurt</h6>
                        </div>
                    </div>
                    <div class="custom-col">
                        <div class="icon-container">
                            <div class="icon-background">
                                <img src="assets/images/book-your-pooja.png" width="100" height="100" alt="pooja icon">
                            </div>
                            <h6>Book your pooja</h6>
                        </div>
                    </div>
                    <div class="custom-col">
                        <div class="icon-container">
                            <div class="icon-background">
                                <img src="assets/images/ritual-at-muhurt.png" width="100" height="100" alt="havan icon">
                            </div>
                            <h6>Conduct ritual at muhurt</h6>
                        </div>
                    </div>
                    <div class="custom-col">
                        <div class="icon-container">
                            <div class="icon-background">
                                <img src="assets/images/feedback.png" width="100" height="100" alt="globe icon">
                            </div>
                            <h6>Feedback</h6>
                        </div>
                    </div>

                </div>
            </div>
        </section>




        <section class="about-module">
            <div class="am-container d-flex">
                <div class="aboutContent">
                    <img src="assets/images/om-img.png" alt="" class="om-img">
                    <div class="imgHeadingBlock">
                        <h6 class="sub-heading">About</h6>
                        <img src="assets/images/vedang-shastra-letters.png" alt="" class="">
                    </div>
                    <p class=""> Vedang Shastra is an online pooja booking portal in India where an attempt has been
                        made to strike a golden balance between technology and tradition. All our Pandits/Priests are
                        trained in certified Vedic Pathshalas and have experience in performing Pooja professionally.
                    </p>

                    <p class=""> Vedang Shastra offers best pooja services in urban India.
                        Book Pandit/Priest online with best price for best puja ceremony. You can book Pandits/Priests
                        for Pooja, Homhavan, Chant, Katha, Patha, Marriage, Sakhpuda Ceremony, Pitru Dosha Pooja, Temple
                        Seva and many other religious rituals. </p>

                    <div class="btn-container mt-4">
                        <button> <a routerLink="/about-us">Learn More</a> </button>
                    </div>
                </div>
                <div class="imgBlock">
                    <img src="assets/images/vastushanti.png" alt="">
                </div>
            </div>
        </section>



        <section class="why-module">
            <div class="container">
                <img src="assets/images/shri-yantra.png" alt="" class="shri-yantra-img">
                <div class="imgHeadingBlock text-center">
                    <h6 class="sub-heading">Why Us</h6>
                    <img src="assets/images/vedang-shastra-letters.png" alt="" class="">
                    <p class="heading-content ">We are the mediator between Yajman and Panditji to strike <br> a golden
                        balance between technology and tradition.</p>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-3 col-xl-3 mb-sm-4 mb-lg-0">
                        <div class="why-box">
                            <div class="img-block">
                                <img src="assets/images/easy-to-book.png" alt="">
                            </div>
                            <h3>Easy to book</h3>
                            <p>Vedang Shastra try to make process convenient for individuals or families to scheduled
                                and participate in various spiritual rituals. </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 col-xl-3 mb-sm-4 mb-lg-0">
                        <div class="why-box">
                            <div class="img-block">
                                <img src="assets/images/experience-pandit.png" alt="">
                            </div>
                            <h3>Experience Pandit</h3>
                            <p>Vedang Shastra has extensive expertise in performing religious ceremonies, rituals, and
                                poojas according to traditional practises. </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 col-xl-3 mb-sm-4 mb-lg-0">
                        <div class="why-box">
                            <div class="img-block">
                                <img src="assets/images/spiritual-remedy.png" alt="">
                            </div>
                            <h3>Spiritual Remedy</h3>
                            <p>Vedang Shastra has aim to bring about a sense of inner peace, emotional healing, and a
                                deeper connection with oneself and the universe through prayer, mantra chanting. </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 col-xl-3 mb-sm-4 mb-lg-0">
                        <div class="why-box">
                            <div class="img-block">
                                <img src="assets/images/vidhi-on-muhurt.png" alt="">
                            </div>
                            <h3>Vidhi on Muhurt</h3>
                            <p>Vedang Shastra commits to do vidhi on muhurt to ensure pooja's positive impact. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section class="bk-yr-pooja-section text-center">
            <div class="container">
                <h3 class="section-heading">SCHEDULE YOUR POOJA</h3>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <p>
                            You are tired of finding pundits! Now that your search is over, you can book a pandit for
                            any of your poojas and our pandits will come to your home and perform the pooja.
                        </p>
                        <button routerLink="/list-of-pooja">Book Now</button>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>