<app-header></app-header>
<div class="page-container">



    <div class="main-content">

        <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
            <div class="container breadcrumb-container">
                <ol class="breadcrumb">
                    <li class="breadcrum-title">Popular Pooja</li>
                    <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img
                                src="assets/icons/home-icon.png" class="" alt=""> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Popular Pooja</li>
                </ol>
            </div>
        </nav>



        <section class="categoryPoojaModule">
            <div class="container">
                <h3 class="section-heading">Popular Pooja</h3>
                <div class="row">
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div class="pooja-card">
                            <div class="img">
                                <img src="assets/images/popular-pooja/durgasthapna.png" alt="shivling">
                            </div>
                            <div class="overlay">
                                <div class="text">
                                    <h3 class="title mb-2">Rudraabhishek</h3>
                                    <h2 class="price mb-4">₹ 101</h2>
                                    <a routerLink="/pooja-details">Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div class="pooja-card">
                            <div class="img">
                                <img src="assets/images/popular-pooja/laghurudra.png" alt="shivling">
                            </div>
                            <div class="overlay">
                                <div class="text">
                                    <h3 class="title mb-2">Rudraabhishek</h3>
                                    <h2 class="price mb-4">₹ 101</h2>
                                    <a routerLink="/pooja-details">Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div class="pooja-card">
                            <div class="img">
                                <img src="assets/images/popular-pooja/navchandiyag.png" alt="shivling">
                            </div>
                            <div class="overlay">
                                <div class="text">
                                    <h3 class="title mb-2">Rudraabhishek</h3>
                                    <h2 class="price mb-4">₹ 101</h2>
                                    <a routerLink="/pooja-details">Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div class="pooja-card">
                            <div class="img">
                                <img src="assets/images/popular-pooja/rudraabhishek.png" alt="shivling">
                            </div>
                            <div class="overlay">
                                <div class="text">
                                    <h3 class="title mb-2">Rudraabhishek</h3>
                                    <h2 class="price mb-4">₹ 101</h2>
                                    <a routerLink="/pooja-details">Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div class="pooja-card">
                            <div class="img">
                                <img src="assets/images/popular-pooja/saptshatipath.png" alt="shivling">
                            </div>
                            <div class="overlay">
                                <div class="text">
                                    <h3 class="title mb-2">Rudraabhishek</h3>
                                    <h2 class="price mb-4">₹ 101</h2>
                                    <a routerLink="/pooja-details">Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>





    </div>




    <app-footer></app-footer>