import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { GlobalService } from 'src/app/service/global.service';
import { PoojaService } from 'src/app/service/pooja.service';

@Component({
  selector: 'app-pooja',
  templateUrl: './pooja.component.html',
  styleUrls: ['./pooja.component.scss']
})
export class PoojaComponent implements OnInit {
  selectedPooja: any;
  poojaname: any;
  poojaData: any[] = [];
  imagePaths: any
  id: any;
  allPooja: any = [];
  imageUrls: string[] = [];
  imagePathsArray: any = [];
  imgUrl: any = 'https://api.vedangshastra.com'

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private poojaService: PoojaService,
    private route: ActivatedRoute,
    private meta: Meta
  ) { }

  ngOnInit() {
    this.updateMetaTags()

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      this.poojaService.getPoojaCategory(this.id).subscribe((res: any) => {
        this.poojaname = res.data[0].category_name;
        this.poojaData = res.data;
      });
    });
  }

  getImageUrl(imagepath: string): string {
    if (imagepath && imagepath !== "null") {
      const imageUrls = imagepath.split(',');
      if (imageUrls.length > 0) {
        return `${this.imgUrl}${imageUrls[0].trim()}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  bookNowPooja(id: any) {
    this.router.navigate(['pooja-details', id]);
  }
  updateMetaTags(){
    this.meta.updateTag(
      { name: 'description', content: '' }, 
    );
    this.meta.updateTag(
      { name: 'keywords', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:title', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:determiner', content: '' }, 
    );
    
  }
}




