<app-header></app-header>
<div class="page-container">
    <div class="main-content">
        <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
            <div class="container breadcrumb-container">
                <ol class="breadcrumb">
                    <li class="breadcrum-title">Select Your Packages</li>
                    <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img
                                src="assets/icons/home-icon.png" class="" alt=""> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Select Your Packages</li>
                </ol>
            </div>
        </nav>

        <section>
            <div class="container package-container">
                <h3>Select Your Package</h3>
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" *ngFor="let pkg of allPkg; let index = index">
                        <div class="package-card">
                            <div class="package-card-header">
                                <h6>{{pkg.package_name}}</h6>
                                <h5>₹ {{pkg.package_price}}</h5>
                            </div>
                            <div class="package-card-list">
                                <p>Procedure Involved</p>
                                <ul>
                                    <li *ngFor="let option of pkg.procedure_options">{{ option }}</li>
                                </ul>
                                <p class="inclusion">Inclusions</p>
                                <ul class="tick-li">
                                </ul>

                                <div class="form-check" *ngFor="let item of allInclusion; let i = index">
                                    <input class="form-check-input" type="checkbox" [value]="item.inclusion_name"
                                        [checked]="checkStatus(item.inclusion_name, index)"
                                        [disabled]="checkStatus(item.inclusion_name, index)"
                                        (change)="getcheckValue($event.target, index)"
                                        [hidden]="checkStatus(item.inclusion_name, index)"
                                        style="opacity: 1 !important">
                                    <span *ngIf="checkStatus(item.inclusion_name, index)">
                                        <img src="assets/icons/check-icon.png" alt="" class="tick-icon">
                                    </span>
                                    <label class="form-check-label" [for]="'flexCheckDefault' + i">{{
                                        item.inclusion_name }}</label>
                                </div>
                            </div>
                            <div class="package-footer mt-2">
                                <p><span>Note:</span>
                                    {{pkg.note}}</p>
                                <div class="btn-container text-center">
                                    <button [hidden]="userLogin"
                                        (click)="viewPackage(pkg.id.toString(),'frompackage',index)"
                                        data-bs-toggle="modal" data-bs-target="#loginModal" id="book">Book</button>

                                    <button [hidden]="!userLogin"
                                        (click)="viewPackage(pkg.id.toString(),'frompackage',index)">Book</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>