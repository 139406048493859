<div class="page-container">
    <app-header></app-header>
    <div class="main-content">
        <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
            <div class="container breadcrumb-container">
                <ol class="breadcrumb">
                    <li class="breadcrum-title">Cancellation Policy</li>
                    <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img
                                src="assets/icons/home-icon.png" class="" alt=""> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Cancellation Policy</li>
                </ol>
            </div>
        </nav>
        <section class="policyModule">
            <div class="container">
                <div class="headingBlock">
                    <h3 class="section-heading text-start mb-3">Cancellations and Refund Policy</h3>
                    <p>
                        Cancellations can be made two days or more before the Pooja event. There will be no refunds for
                        cancellations made within 2 days or 48 hours of the event. Refunds will not be provided for
                        Poojas Cancelled on that particular day during that muhurth time due to any reason from the
                        devotees side. Notice of cancellation must be made via email to info@vedangshastra.com. A
                        cancellation acknowledgement will be sent within 2 working days, and fees will be refunded
                        within 15 working days, subject to this Refund Policy.
                        <br><br>
                        If Vedangshastra LLP offers a Pooja service, we do not issue refunds once the order is
                        completed. As a customer, you are responsible for understanding this refund policy upon
                        purchasing any product or service at our web site. However, we realise that exceptional
                        circumstances can arise with regard to the character of the product or services we provide.
                        <br><br>
                        Therefore, We honour requests for refunds where the

                        <br><br>

                        <span class="boldFont"><b>The following reasons apply:</b></span>
                    <ol type="1" class="mb-4">
                        <li>Non-delivery of the product or service: such as, for example, if, due to some mailing
                            issues, you do not receive a delivery email from us.</li>
                        <li>A few software mistakes or manual difficulties with the product or service occur sometimes;
                            although all the products and services are thoroughly reviewed before release, unexpected
                            errors may occur. This reason should be submitted to our Support Team for approval
                            (info@vedangshastra.com)</li>
                    </ol>

                    <span class="boldFont"><b>Contact Us</b></span>
                    <p class="mt-3">
                        Please note that our Support Team (info@vedangshastra.com) is ready to provide you with timely
                        and efficient professional assistance. We will attempt to find the most convenient solution for
                        your query. Give us 12–24 hours for our Support Team to get back to you on the problem.
                    </p>


                    <span class="boldFont"><b>Payment Policy</b></span>
                    <p class="mt-3">
                        Registration is not complete until advance or full payment has been received. Advance or full
                        Payments not received before the Pooja will result in the non-delivery of our service. Payment
                        may be made by Cash, cheque, Visa, MasterCard or Net banking.
                    </p>

                </div>

            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>