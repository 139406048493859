<app-header></app-header>
<div class="page-container">
  <div class="main-content">
    <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
      <div class="container breadcrumb-container">
        <ol class="breadcrumb">
          <li class="breadcrum-title">Book pooja</li>
          <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img src="assets/icons/home-icon.png"
                class="" alt=""> </a></li>
          <li class="breadcrumb-item active" aria-current="page">Book Pooja</li>
        </ol>
      </div>
    </nav>
    <section>
      <div class="container pooja-container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-12" *ngFor="let option of poojaData; let i = index">
            <div class="img-container">
              <img src="{{imgUrl}}{{ imagePathsArray[imgcount] }}" alt="" class="main-img">
              <div class="read-more-content readMoreSlider">
                <owl-carousel-o [options]="productSliderOptions">
                  <ng-container *ngFor="let imagePath of imagePathsArray; let i = index">
                    <ng-template carouselSlide>
                      <div class="owl-item">
                        <div class="productImg">
                          <img (click)="Setzoomimg(i)" [src]="imgUrl + imagePath" alt="Image {{ i }}">
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>

            </div>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-7 col-sm-7 col-12" *ngFor="let option of poojaData">
            <h3 class="pooja-heading">{{option.name}}</h3>
            <p class="pooja-price">Price Range : <span>₹ {{option.price_range_from}} - ₹
                {{option.price_range_to}}</span></p>

            <div class="pooja-content expand">
              <p>{{option.description}}</p>

              <div>
                <div class="insight-promises">
                  <div class="row">
                    <div class="col-lg-6">
                      <h6>Key Insights:</h6>
                      <ul>
                        <li *ngFor="let insight of keyInsights">{{ insight }}</li>
                      </ul>
                    </div>

                    <div class="col-lg-6">
                      <h6>Our Promises:</h6>
                      <ul>
                        <li *ngFor="let promises of keyPromises">{{ promises }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="d-flex read-mr-container mt-3">
              <button (click)="viewPackages(option.id.toString())">View Pricing and Packages</button>
              <div class="pooja-mode-container ms-4 d-flex align-items-center">
                <h6 class="me-2 mb-0">Pooja Perform :</h6>

                <div class="form-check me-2">
                  <input class="form-check-input" type="radio" name="pooja_mode" id="at_home" checked>
                  <label class="form-check-label me-5" for="at_home">At Home</label>
                </div>
                <div>
                  <label class="form-check-label" for="at_home"><span style="color: brown;">Location :</span>&nbsp;
                    <b>Mumbai</b></label>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="pooja-tab-section">
      <div class="container pooja-tab-container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngFor="let option of poojaData">
            <ul class="nav nav-pills nav-fill mb-4" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-samagri-list-tab" data-bs-toggle="pill"
                  data-bs-target="#pills-samagri-list" type="button" role="tab" aria-controls="pills-samagri-list"
                  aria-selected="false">Samagri List</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-suggested-muhurt-tab" data-bs-toggle="pill"
                  data-bs-target="#pills-suggested-muhurt" type="button" role="tab"
                  aria-controls="pills-suggested-muhurt" aria-selected="false">Suggested Muhurt</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-importance-tab" data-bs-toggle="pill"
                  data-bs-target="#pills-importance" type="button" role="tab" aria-controls="pills-importance"
                  aria-selected="true">Importance</button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-samagri-list" role="tabpanel"
                aria-labelledby="pills-samagri-list-tab">

                <table class="samagri-table">
                  <tr>
                    <th>Samagri name</th>
                    <th>Quantity</th>
                  </tr>
                  <tr *ngFor="let item of samagriList">
                    <td>{{item.name}}</td>
                    <td>{{item.quantity}}</td>
                  </tr>

                </table>
              </div>
              <div class="tab-pane fade" id="pills-suggested-muhurt" role="tabpanel"
                aria-labelledby="pills-suggested-muhurt-tab">
                <table class="muhurt-table">
                  <tr>
                    <th>Month</th>
                    <th>Date</th>
                  </tr>
                  <tr *ngFor="let item of suggestedMuhurtList">
                    <td>{{ item.name}}</td>
                    <td>{{item.date}}</td>
                  </tr>
                
                </table>
              </div>
              <div class="tab-pane fade " id="pills-importance" role="tabpanel" aria-labelledby="pills-importance-tab">
                <ol>
                  <li *ngFor="let importance of keyimportance">{{ importance }}</li>
                </ol>

              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
  <app-footer></app-footer>
</div>