import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GlobalService } from 'src/app/service/global.service';
import { PoojaService } from 'src/app/service/pooja.service';
import { Meta } from '@angular/platform-browser';
 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  poojaname: any;
  poojaData: any[] = [];
  id: any;
  popularpooja: any[] = [];
  allPooja: any = [];
  poojaimg: any;
  apiurl = 'https://api.vedangshastra.com'
  imgUrl: any = 'https://api.vedangshastra.com'
 
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    nav: true,
    margin: 20,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      },
      1000:{
        items:3
      },
      1400:{
        items:4
      }
    },
  }
 
 
  slides = [
   
 
  ];
 
 
  customHomeSliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    // navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    nav: true,
    margin: 10,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }
 
  constructor(private formBuilder: FormBuilder, private poojaService: PoojaService, private globalService: GlobalService, private router: Router,private meta: Meta) {
 
  }
 
 
  ngOnInit(): void {
   
    this.poojaService.getPopularPooja().subscribe((res: any) => {
      this.allPooja = res.data;
      console.log(this.allPooja);
     
      // this.popularpooja = this.allPooja.filter((item: { category_name: string }) => item.category_name === 'Popular Pooja');
    });
    this.updateMetaTags()
  }
 
  getImageUrl(imagepath: string): string {
    if (imagepath && imagepath !== "null") {
      const imageUrls = imagepath.split(',');
      return `${this.imgUrl}${imageUrls[0].trim()}`;
    } else {
      return '';
    }
  }
 
  // visitPooja(id: any) {
  //   this.router.navigateByUrl("pooja-details/" + id.poojaId)
  // }
 
  bookNow(poojaId: any) {
    this.globalService.selectedPooja.next(poojaId); // Use next to set the value
    this.router.navigate(['pooja-details', poojaId]);
  }
  updateMetaTags(){
    this.meta.updateTag(
      { name: 'description', content: '' },
    );
    this.meta.updateTag(
      { name: 'keywords', content: '' },
    );
    this.meta.updateTag(
      { property: 'og:title', content: '' },
    );
    this.meta.updateTag(
      { property: 'og:determiner', content: '' },
    );
   
  }
 
}
 