<footer>
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-xl-6 col-lg-6  d-flex copyright">
                <p>©2023 Vedang shastra. All Rights Reserved</p>
            </div>
            <div class="col-xl-6 col-lg-6 d-flex align-item-center justify-content-end copyright-col">
                <div class="termsPages">
                    <a routerLink="/terms-nd-condition">Terms & Conditions</a> |
                    <a routerLink="/privacy-policy">Privacy Policy</a> |
                    <a routerLink="/cancellation-policy">Cancellation Policy</a>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="icon-bar">
    <a href="https://www.youtube.com/@VedangShastra" class="youtube">
        <img src="assets/icons/youtube1.svg" alt="">
    </a>
    <a href="https://www.facebook.com/people/VedangShastra/61553478774757/"   class="facebook">
        <img src="assets/icons/facebook1.svg" alt="">
    </a>
    <a href="https://www.instagram.com/vedangshastra/"   class="instagram">
        <img src="assets/icons/instagram1.svg" alt="">
    </a>
</div>

<div class="btt-option">
    <div class="back-to-top" *ngIf="isScrolled">
        <a id="back-to-top" (click)="scrollToTop()">
            <img src="assets/icons/chevron-up.svg" alt="">
        </a>
    </div>
</div>