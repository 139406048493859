import { Component, } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { GlobalService } from 'src/app/service/global.service';
import { PoojaService } from 'src/app/service/pooja.service';
import { HeaderComponent } from 'src/app/shared/header/header.component';

interface Samagri {
  quantity: string;
  name: string;
}
interface Muhurt {
  date: string;
  name: string;
}

@Component({
  selector: 'app-pooja-details',
  templateUrl: './pooja-details.component.html',
  styleUrls: ['./pooja-details.component.scss']
})
export class PoojaDetailsComponent {
  showReadMore: boolean = false;
  imageUrls: string[] = [];
  dropdid: any
  poojaname: any;
  id: any;
  selectedPooja: any;
  poojaData: any = [];
  keyInsights: any[] = [];
  keyPromises: any[] = [];
  keyimportance: any[] = [];
  imagePathsArray: any = [];

  image0: any = [];
  // imgUrl: any = 'http://www.api.vedangshastra.com'
  imgUrl: any = 'https://api.vedangshastra.com'
  apiURL: any = 'https://api.vedangshastra.com'
  imgcount:any = 0

  toggleReadMore() {

    const readMoreButton = document.querySelector('.read-more-btn');
    const poojaContent = document.querySelector('.pooja-content');

    if (readMoreButton && poojaContent) {
      readMoreButton.addEventListener('click', () => {
        poojaContent.classList.toggle('expand');
        this.showReadMore = !this.showReadMore;
      });
    }
  }

  productSliderOptions = {
    items: 3, // Number of items to display in the carousel
    loop: true, // Enable looping
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Autoplay interval in milliseconds
    nav: false, // Enable navigation arrows
    dots: false, // Enable pagination dots
    margin: 10,
  };


  constructor(private globalService: GlobalService, private route: ActivatedRoute, private poojaService: PoojaService, private router: Router,private meta: Meta) {
    this.id = this.route.snapshot.params['id'];
  }
 
  poojaDetails: any = [];
  samagri_list: any;
  importance: any;
  imagePath: any;
  insights: any;
  promises: any;
  samagriList: Samagri[] = [];
  suggestedMuhurtList: Muhurt[] = [];

  ngOnInit(): void {
    const pooja_Details = this.id;
    localStorage.setItem('pooja_Details', pooja_Details);
    if (this.id) {
    }
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      this.poojaService.getPoojaDetails(this.id || '').subscribe((res: any) => {
        // console.log("get pooja details res",res.data[0].id);
        
        if(res.data.length > 0){
          this.poojaname = res.data[0].category_name;
        const imagePathString = res.data[0].imagepath;
        this.imagePathsArray = imagePathString?.split(', ') || '';
        this.poojaData = res.data;
        let splittedData = res.data[0].insights.split('. ')
        splittedData.forEach((data: any) => {
          this.keyInsights.push(data)
        })
        let splitPromises = res.data[0].promises.split(".")
        splitPromises.forEach((data: any) => {
          this.keyPromises.push(data)
        })
        this.keyPromises.pop()
        let splitimportance = res.data[0].importance.split(".")
        splitimportance.forEach((data: any) => {
          this.keyimportance.push(data)
        })
        this.keyimportance.pop()
        const samagriListData = res.data[0].samagri_listNode;
        if (Array.isArray(samagriListData)) {
          this.samagriList = samagriListData;
        }
        const suggestedMuhurtData = res.data[0].suggestedmuhurtaNode;
        if (Array.isArray(suggestedMuhurtData)) {
          this.suggestedMuhurtList = suggestedMuhurtData;
        }
        }else{
          this.router.navigateByUrl("/notfound")
        }

      },(error)=>{
        this.router.navigateByUrl("/notfound")
      }
      );

    });
    this.updateMetaTags()


  }

  routePackage(id: any) {
    this.router.navigate(['packages', id])
  }

  viewPackages(id: any) {
    this.router.navigate(['packages', id]);
  }
  updateMetaTags(){
    this.meta.updateTag(
      { name: 'description', content: '' }, 
    );
    this.meta.updateTag(
      { name: 'keywords', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:title', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:determiner', content: '' }, 
    );
    
  }

  ngDestroy() {

  }

  Setzoomimg(value:any){
    this.imgcount = value
  }
}
