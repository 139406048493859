import { NgModule, isDevMode, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS  } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './components/home/home.component';
import { ListOfPoojaComponent } from './components/list-of-pooja/list-of-pooja.component';
import { MuhurtComponent } from './components/muhurt/muhurt.component';
import { PackagesComponent } from './components/packages/packages.component';
import { YajmanAddressComponent } from './components/yajman-address/yajman-address.component';
import { PoojaDetailsComponent } from './components/pooja-details/pooja-details.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DetailsOfListComponent } from './components/details-of-list/details-of-list.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VastuComponent } from './components/consultancy/vastu/vastu.component';
import { AstrologyComponent } from './components/consultancy/astrology/astrology.component';
import { MyBookingComponent } from './components/my-booking/my-booking.component';
import { PoojaComponent } from './shared/pooja-dropdown/pooja/pooja.component';
import { TermsNdConditionComponent } from './components/terms-nd-condition/terms-nd-condition.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CancellationPolicyComponent } from './components/cancellation-policy/cancellation-policy.component';
import { environment } from 'src/environments/environment.development';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LoadingInterceptor } from './service/loading.interceptor';
import { NotfoundComponent } from './components/notfound/notfound.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutUsComponent,
    ContactUsComponent,
    HomeComponent,
    ListOfPoojaComponent,
    MuhurtComponent,
    PackagesComponent,
    YajmanAddressComponent,
    PoojaDetailsComponent,
    HeaderComponent,
    FooterComponent,
    DetailsOfListComponent,
    VastuComponent,
    AstrologyComponent,
    MyBookingComponent,
    PoojaComponent,
    TermsNdConditionComponent,
    PrivacyPolicyComponent,
    CancellationPolicyComponent,
    SpinnerComponent,
    NotfoundComponent,


  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    DatePipe,
    BrowserAnimationsModule, ReactiveFormsModule, HttpClientModule, FormsModule, ToastrModule.forRoot(), ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
