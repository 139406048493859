<app-header></app-header>
<div class="page-container">

    <div class="main-content">

        <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
            <div class="container breadcrumb-container">
                <ol class="breadcrumb">
                    <li class="breadcrum-title">Contact us</li>
                    <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img
                                src="assets/icons/home-icon.png" class="" alt=""> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact us</li>
                </ol>
            </div>
        </nav>



        <section>
            <div class="container">
                <div class="centerBlock">
                    <h5>Contact us</h5>
                    <h1>We’d love to provide a spiritual remedy for you.</h1>
                </div>
                <form [formGroup]="contactUsForm">
                    <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                            <div class="left-contact-block cardBlock">
                                <div class="row">
                                    <div class="col-lg-12 mb-4">
                                        <div class="icon-container d-flex">
                                            <div class="icon-background mb-2">
                                                <img src="assets/icons/phone2.svg" width="100" height="100"
                                                    alt="Phone Number">
                                            </div>
                                            <h3>Call Us</h3>
                                        </div>
                                        <div class="content">
                                            <h6>+91 8655346055</h6>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-4">
                                        <div class="icon-container d-flex">
                                            <div class="icon-background mb-2">
                                                <img src="assets/icons/location2.svg" width="100" height="100"
                                                    alt="Address">
                                            </div>
                                            <h3>Location</h3>
                                        </div>
                                        <div class="content">
                                            <h6>Thane & Mumbai</h6>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-4">
                                        <div class="icon-container d-flex">
                                            <div class="icon-background mb-2">
                                                <img src="assets/icons/mail2.svg" width="100" height="100"
                                                    alt="Email Address">
                                            </div>
                                            <h3>Email</h3>
                                        </div>
                                        <div class="content">
                                            <h6>vedangshastra@gmail.com</h6>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-4">
                                        <div class="icon-container d-flex">
                                            <div class="icon-background mb-2">
                                                <img src="assets/icons/clock-01.svg" width="100" height="100"
                                                    alt="Clock">
                                            </div>
                                            <h3>Business Hours</h3>
                                        </div>
                                        <div class="content">
                                            <h6>9 AM to 6 PM </h6>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div class="col-xxl-6 col-xl-6 col-lg-6">
                            <div class="right-form-block cardBlock">
                                <div class="row">
                                    <div class="col-xxl-6 col-lg-6 col-sm-6">
                                        <div class="mb-md-3 mb-3 custom-form">
                                            <label for="exampleFormControlInput" class="form-label">First
                                                Name</label><span class="required">* </span>
                                            <div class="custom-input">
                                                <input type="text" class="form-control" id="exampleFormControlInput"
                                                    placeholder="First Name" formControlName="firstName"
                                                    name="firstName" (keypress)="validateAlphabet($event)">
                                                <div class="error-message" *ngIf="
                                                contactUsForm.get('firstName')?.invalid &&
                                                contactUsForm.get('firstName')?.touched
                                              ">
                                                    First name is required.
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-xxl-6 col-lg-6 col-sm-6">
                                        <div class="mb-md-3 mb-3 custom-form">
                                            <label for="exampleFormControlInput1" class="form-label">Last
                                                Name</label><span class="required">* </span>
                                            <div class="custom-input">
                                                <input type="text" class="form-control" id="exampleFormControlInput1"
                                                    placeholder="Last Name" formControlName="lastName" name="lastName"
                                                    (keypress)="validateAlphabet($event)">
                                                <div class="error-message" *ngIf="
                                                contactUsForm.get('lastName')?.invalid &&
                                                contactUsForm.get('lastName')?.touched
                                              ">
                                                    Last name is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xxl-6 col-lg-6 col-sm-6">
                                        <div class="mb-md-3 mb-3 custom-form">
                                            <div class="mb-md-3 mb-3 custom-form">
                                                <label for="exampleFormControlInput2" class="form-label">Mobile
                                                    No.</label><span class="required">* </span>
                                                <div class="custom-input">
                                                    <input type="text" class="form-control"
                                                        id="exampleFormControlInput2" placeholder="Mobile No."
                                                        formControlName="mobilenumber" name="mobilenumber"
                                                        (keypress)="validateMobileNumber($event)">
                                                    <div class="error-message" *ngIf="contactUsForm.get('mobilenumber')?.value == '' &&
                                                    contactUsForm.get('mobilenumber')?.invalid &&
                                                    contactUsForm.get('mobilenumber')?.touched
                                                ">
                                                        Mobile number is required.
                                                    </div>
                                                    <div class="error-message" *ngIf="
                                                    contactUsForm?.get('mobilenumber')?.hasError('minlength') && contactUsForm.get('mobilenumber')?.invalid &&
                                                    contactUsForm?.get('mobilenumber')?.touched
                                                ">
                                                        Mobile number should be exactly 10 digits.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xxl-6 col-lg-6 col-sm-6">
                                        <div class="mb-md-3 mb-3 custom-form">
                                            <label for="exampleFormControlInput3" class="form-label">Email</label><span
                                                class="required">* </span>
                                            <div class="custom-input">
                                                <input type="tel" class="form-control" id="exampleFormControlInput3"
                                                    placeholder="Email Address" formControlName="email" name="email">
                                                <div class="error-message" *ngIf="
                                                contactUsForm.get('email')?.invalid &&
                                                contactUsForm.get('email')?.touched
                                              ">
                                                    Email is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="mb-md-3 mb-3 custom-form">
                                            <label for="exampleFormControlInput3"
                                                class="form-label">Message</label><span class="required">* </span>
                                            <textarea class="form-control" id="exampleFormControlTextarea"
                                                placeholder="Message" rows="6" formControlName="message"
                                                name="message"></textarea>
                                            <div class="error-message" *ngIf="
                                            contactUsForm.get('message')?.invalid &&
                                            contactUsForm.get('message')?.touched
                                          ">
                                                Message is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-3 contactBtn">
                                        <div class="btn-container">
                                            <button class="smHover" (click)="onSubmit()">Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>

            </div>

        </section>
    </div>
    <app-footer></app-footer>

</div>




<script src="https://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js"></script>
<script src="assets/js/bootstrap.bundle.min.js"></script>
<script src="assets/js/owl-carousel/owl.carousel.min.js"></script>
<script src="assets/js/main.js"></script>
<script>

    $(document).ready(function () {

        $(function () {
            $("#my_date_picker").datepicker({ showOn: 'button', buttonImageOnly: true, buttonImage: 'assets/icons/calendar-icon.png' });
        });
    })
</script>