import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class PoojaService {

  // baseURL: any = 'http://www.api.vedangshastra.com'
  // baseURL: any = 'https://api.vedangshastra.com'
  baseURL: any = environment.apiurl

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': 'true',
      'Access-Control-Allow-Origin': '*',

    }),
  };

  //0=pooja , 1=Consultancy
  poojaOrConsultancy = new BehaviorSubject<any>(0)

  //0 for Astro and 1 for vastu
  consultancyHideField = new BehaviorSubject<any>('')

  //yajman ids for pooja and consultancy
  local_yajman_id: any = new BehaviorSubject<any>('')
  local_yajman_consultancy_id = new BehaviorSubject<any>('')

  //get Yajman Email
  yajmanEmail = new BehaviorSubject('')

  poojaListArray = new BehaviorSubject<any>('')

  constructor(private http: HttpClient) { }
  //  Pooja Category 
  getPoojaCategory(id: any): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/category/getPoojaByCategory?id=${id}`, this.httpOptions)
  }

  //  get Tab Hover  dropdown
  getpoojaDropdown(): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/category/getPoojaCategory`, this.httpOptions);
  }

  // Book Pooja
  getBookPooja(id: any): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/category/getPoojaDetails?id=${id}`, this.httpOptions);
  }

  getPackege(id: any): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/package/getPackageList?id=${id}`, this.httpOptions);
  }

  getPackegeone(id: any): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/package/getPackageData?packageId=${id}`, this.httpOptions)
  }

  // Package Inclusion
  getPkgInclusion(): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/package/getInclusionData`, this.httpOptions)
  }

  // All pooja list
  getallPooja() {
    return this.http.get(this.baseURL + `/api/v1/pooja/getPoojaList`, this.httpOptions);
  }
  getPopularPooja(): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/category/getPopularPooja`, this.httpOptions)
  }

  getPoojaDetails(id: any): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/category/getPoojaDetails?id=${id}`, this.httpOptions)
  }


  // Yajman address post api call
  postYajmanAddress(formData: any): Observable<any> {
    return this.http.post(this.baseURL + `/api/v1/yajmandetails/save`, formData, this.httpOptions)
  }

  getYajmanDetails(user_id: any): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/yajmandetails/getYajmanDetails?yajmanid=${user_id}`, this.httpOptions)
  }

  updateYajmanDetails(id: any, formData: any): Observable<any> {
    return this.http.put(this.baseURL + `/api/v1/yajmandetails/updateYajmanDetails?id=${id}`, formData, this.httpOptions)
  }

  getYajmanConsultancyDetails(user_id: any): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/consultancy/getConsultancyYajmanDetails?yajmanid=${user_id}`, this.httpOptions)
  }

  updateYajmanConsultancyDetails(id: any, formData: any): Observable<any> {
    return this.http.put(this.baseURL + `/api/v1/consultancy/updateConsultancyYajmanDetails?id=${id}`, formData, this.httpOptions)
  }

  //cancel pooja and consultancy
  cancelYajmanPooja(id: any): Observable<any> {
    return this.http.put(this.baseURL + `/api/v1/booking/cancelBooking?id=${id}`, this.httpOptions)
  }

  cancelYajmanConsultancy(id: any): Observable<any> {
    return this.http.put(this.baseURL + `/api/v1/booking/cancelConsultancyBooking?id=${id}`, this.httpOptions)
  }

  bookPooja(data: any): Observable<any> {
    return this.http.post(this.baseURL + `/api/v1/booking/saveBooking`, data)
  }

  bookConsultancy(data: any): Observable<any> {
    return this.http.post(this.baseURL + `/api/v1/booking/saveConsultancyBooking`, data, this.httpOptions)
  }

  getBookingDetails(userId: any): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/booking/getBookingDetails?userId=${userId}`, this.httpOptions)
  }

  getBookingConsultancyDetails(user_id: any): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/booking/getConsultancyBookingDetails?userId=${user_id}`, this.httpOptions)
  }


}
