import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConsultancyService } from 'src/app/service/consultancy.service';
import { GlobalService } from 'src/app/service/global.service';
import { CryptojsService } from 'src/app/service/cryptojs.service';

@Component({
  selector: 'app-vastu',
  templateUrl: './vastu.component.html',
  styleUrls: ['./vastu.component.scss']
})
export class VastuComponent implements OnInit {
  consultancydetails: any[] = [];
  consultancy_procedure_options: any[] = [];
  description_lines: any[] = [];
  id: any;
  package_price: any;
  consultancy_name: any;
  booking_amount: any;
  priceDifference: any = 0
  adVanceAmnt: any = 0;
  finalTotalamnt: any = 0
  imgUrl: any = 'https://api.vedangshastra.com'

  userLogin: boolean = true;

  constructor(
    private consultancyservice: ConsultancyService,
    private router: Router,
    private route: ActivatedRoute, // Inject ActivatedRoute
    private toaster: ToastrService,
    private globalService: GlobalService,
    private cryptojs : CryptojsService
  ) { }

  ngOnInit(): void {
    localStorage.setItem("checkPorC", "consultancy");

    //check user login or not
    this.globalService.userLogin.subscribe((res: any) => {
      if (res || this.cryptojs.decryptLocalStorage()) {
        this.userLogin = false;
      } else {
        this.userLogin = true;
      }

    })
    // Access the 'id' route parameter using ActivatedRoute
    this.route.paramMap.subscribe((params) => {
      this.id = params.get('id');
      if (this.id) {
        this.consultancyDetails(this.id);
      }
    });
  }

  consultancyDetails(id: any) {
    this.consultancyservice.getConsultancyDetails(this.id).subscribe((res: any) => {
      this.consultancydetails = res.data;
      this.consultancydetails.forEach((ele: any) => {
        this.package_price = ele.package_price
        this.consultancy_name = ele.consultancy_name
        this.booking_amount = ele.booking_amount
        this.consultancy_procedure_options = ele.consultancy_procedure_option.split('. ');
        this.description_lines = ele.description.split('. ');
      })
    });
  }

  getImageUrl(imagepath: string): string {
   
    if (imagepath && imagepath !== "null") {
      const imageUrls = imagepath.split(',');
      return `${this.imgUrl}${imageUrls}`;
    } else {
      return '';
    }
  }


  viewPackage(id: any, idval: any) {

    if (this.cryptojs.decryptLocalStorage()) {
      this.consultancyservice.selectedConsultancy.next(id); // Use next to set the value
      this.router.navigate(['yajman-address', encodeURI(`${id}/?value=${idval}`)]);
      this.cryptojs.encryptLocalStorageCPP( this.package_price)
      localStorage.setItem("consultancy_yajman_id", this.id)
      localStorage.setItem("consultancy_name", this.consultancy_name),
      this.cryptojs.encryptLocalStorageCBA(this.booking_amount)
      this.cryptojs.encryptLocalStoragePV(this.consultancydetails[0].package_price)
    } else {
      this.toaster.error("Login Required, Please login first")
    }

  }
}
