<app-header></app-header>
<div class="page-container">

    <div class="main-content">

        <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
            <div class="container breadcrumb-container">
                <ol class="breadcrumb">
                    <li class="breadcrum-title">Muhurt</li>
                    <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img
                                src="assets/icons/home-icon.png" class="" alt=""> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Muhurt</li>
                </ol>
            </div>
        </nav>

        <section>
            <div class="container">

                <div class="muhurt">
                    <div class="headingBlock">
                        <h3 class="section-heading mb-3">Muhurt</h3>
                        <p class="text-center">If any work is started at an auspicious time or on time, the chances of
                            getting the work done quickly or successfully are strong. We can find this auspicious time
                            in Chaughadia. Here, we have provided the facility to watch Chaughadia. </p>
                    </div>
                    <div class="muhurtTable">
                        <table class="table text-center table-bordered mt-5">
                            <thead>
                                <tr>
                                    <th scope="col">Muhurt Name</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let option of muhurtData">
                                <tr>
                                    <td>{{option.muhurtaname}}</td>
                                    <td>{{option.start_date_time}}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="headingBlock my-5">
                    <h3 class="section-heading text-start mb-3">Shiv Likhit</h3>
                    <p>The duration of each Chaudhaya is one and a half hours. Chaudhaya is divided into three parts:
                        auspicious, medium and inauspicious according to time. In this, auspicious work should be
                        avoided during the inauspicious Chaudhaya. </p>
                </div>

                <div class="indicator mb-4 d-flex justify-content-center">
                    <div class="row justify-content-start">
                        <div class="amrutBox d-flex align-items-center">
                            <span class="square"></span>
                            <span>Amrut</span>
                        </div>
                        <div class="shubhBox d-flex align-items-center">
                            <span class="square"></span>
                            <span>Shubh</span>
                        </div>

                        <div class="labhBox d-flex align-items-center">
                            <span class="square"></span>
                            <span>Labh</span>
                        </div>
                        <div class="charBox d-flex align-items-center">
                            <span class="square"></span>
                            <span>Char</span>
                        </div>
                    </div>
                </div>

                <div class="day-shiv-likhit mb-5">
                    <h3 class="section-heading text-start mb-4">Day Shiv Likhit</h3>
                    <table class="table table-bordered mt-4 text-center table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                                <th scope="col">Sun</th>
                                <th scope="col">Mon</th>
                                <th scope="col">Tue</th>
                                <th scope="col">Wed</th>
                                <th scope="col">Thu</th>
                                <th scope="col">Fri</th>
                                <th scope="col">Sat</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>6.00 PM</td>
                                <td>7.30 PM</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                            </tr>
                            <tr>
                                <td>7.30 PM</td>
                                <td>9.00 PM</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                            </tr>
                            <tr>
                                <td>9.00 PM</td>
                                <td>10.30 PM</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                            </tr>
                            <tr>
                                <td>10.30 PM</td>
                                <td>12.00 AM</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                            </tr>
                            <tr>
                                <td>12.00 AM</td>
                                <td>1.30 AM</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                            </tr>
                            <tr>
                                <td>1.30 AM</td>
                                <td>3.00 AM</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                            </tr>
                            <tr>
                                <td>3.00 AM</td>
                                <td>4.30 AM</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                            </tr>
                            <tr>
                                <td>4.30 AM</td>
                                <td>6.00 AM</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div class="night-shiv-likhit">
                    <h3 class="section-heading text-start mb-4">Night Shiv Likhit</h3>
                    <table class="table table-bordered mt-4 text-center table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                                <th scope="col">Sun</th>
                                <th scope="col">Mon</th>
                                <th scope="col">Tue</th>
                                <th scope="col">Wed</th>
                                <th scope="col">Thu</th>
                                <th scope="col">Fri</th>
                                <th scope="col">Sat</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>6.00 PM</td>
                                <td>7.30 PM</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                            </tr>
                            <tr>
                                <td>7.30 PM</td>
                                <td>9.00 PM</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                            </tr>
                            <tr>
                                <td>9.00 PM</td>
                                <td>10.30 PM</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                            </tr>
                            <tr>
                                <td>10.30 PM</td>
                                <td>12.00 AM</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                            </tr>
                            <tr>
                                <td>12.00 AM</td>
                                <td>1.30 AM</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                            </tr>
                            <tr>
                                <td>1.30 AM</td>
                                <td>3.00 AM</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                            </tr>
                            <tr>
                                <td>3.00 AM</td>
                                <td>4.30 AM</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                            </tr>
                            <tr>
                                <td>4.30 AM</td>
                                <td>6.00 AM</td>
                                <td>Udveg</td>
                                <td class="amrut">Amrut</td>
                                <td>Rog</td>
                                <td class="labh">Labh</td>
                                <td class="shubh">Shubh</td>
                                <td class="char">Char</td>
                                <td>Kal</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="bg-module mt-5">
                    <p> <span class="note">Note:</span> Day and night Chaughadia starts from sunrise and sunset
                        respectively. Sunrise and sunset times may vary from place to place.</p>
                </div>

            </div>
        </section>

    </div>

    <app-footer></app-footer>
</div>