import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-nd-condition',
  templateUrl: './terms-nd-condition.component.html',
  styleUrls: ['./terms-nd-condition.component.scss']
})
export class TermsNdConditionComponent {

}
