<div class="page-container">
    <app-header></app-header>
    <div class="main-content">
        <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
            <div class="container breadcrumb-container">
                <ol class="breadcrumb">
                    <li class="breadcrum-title">List of Pooja</li>
                    <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img
                                src="assets/icons/home-icon.png" class="" alt=""> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">List of Pooja</li>
                </ol>
            </div>
        </nav>
        <section class="categoryPoojaModule">
            <div class="container">
                <div class="poojaCategoryRow mb-5" *ngFor="let data of allPooja">

                    <div class="row align-items-center ">
                        <div class="col-6">

                            <h3 class="section-heading">{{data.category_name}}</h3>
                        </div>
                        <div class="col-6 text-end">
                            <a (click)="viewAll(data.pooja_category_id.toString())" class="view-all">View All</a>
                        </div>
                    </div>
                    <div class="row g-3">
                        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let obj of data.names;let i = index">
                            <div class="poojaCard" *ngIf="i <= 3">
                                <div class="pooja-card">
                                    <div class="img" (click)="bookNow(obj.poojaId.toString())">
                                        <img [src]="getImageUrl(obj.imagepath)" alt="">
                                    </div>
                                </div>
                                <div class="cardFooter mt-2">
                                    <h3 class="title mb-3" (click)="bookNow(obj.poojaId.toString())">{{obj.poojaname}}
                                    </h3>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h2 class="price">₹ {{obj.bookingAmount}}</h2>
                                        <button (click)="bookNow(obj.poojaId?.toString())">Book Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <app-footer></app-footer>
</div>