import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';
@Injectable({
  providedIn: 'root'
})
export class MuhurtService {
  // baseURL: any = 'http://www.api.vedangshastra.com'
  // baseURL: any = 'https://api.vedangshastra.com'
  baseURL: any = environment.apiurl

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': 'true',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  constructor(private http: HttpClient) { }

  getMuhurt(): Observable<any> {
    return this.http.get(this.baseURL + `/api/v1/muhurta/getMuhurtaList`, this.httpOptions);
  }


}
