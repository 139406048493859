import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/service/global.service';
import { PoojaService } from 'src/app/service/pooja.service';
import { CryptojsService } from 'src/app/service/cryptojs.service';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {
  allPkg: any = [];
  allInclusion: any = [];
  id: any;
  storechcekboxval: any;
  storechekvalue: any = [];
  pooojaprice: any;
  finalprice: any
  totalvalue: any | number;

  userLogin: boolean = false;



  constructor(private router: Router, private route: ActivatedRoute, private poojaservice: PoojaService, private globalService: GlobalService,private cryptojs : CryptojsService,private meta: Meta) {
    this.id = this.route.snapshot.params['id'];
    this.globalService.userLogin.subscribe((res: any) => {
      this.userLogin = res
    })
  }

  ngOnInit(): void {
    if (this.cryptojs.decryptLocalStorage()) {
      this.userLogin = true;
    }
    this.viewall();
    this.viewInclusionPkg();
    this.updateMetaTags()

  }

  checkStatus(item: any, index: any) {
    return this.allPkg[index].inclusion_names.includes(item)
  }

  viewall() {
  
    this.poojaservice.getPackege(this.id).subscribe((res: any) => {
      this.allPkg = res.data;
      console.log("get res data",res.data);
      if(res.data.length > 0){
        this.allPkg.forEach((ele: any) => {
          ele.procedure_options = ele.procedure_options.split('.').slice(1).map((option: string) => option.trim());
          this.pooojaprice = ele.package_price;
        });
      }else{
        this.router.navigateByUrl("/notfound")
      }
    
    },(error)=>{
      this.router.navigateByUrl("/notfound")
    });
  }




  viewPackage(id: any, idval: any, packageId: any) {
    //book pooja
    localStorage.setItem("checkPorC", "pooja")
    localStorage.setItem("consultancy_name", "Pooja")
    this.cryptojs.encryptLocalStoragePA( this.allPkg[packageId].package_price)
    localStorage.setItem('package_id', id);
    this.cryptojs.encryptLocalStoragePV(this.allPkg[packageId].package_price)
    this.globalService.selectedPooja.next(id); // Use next to set the value
    if (this.cryptojs.decryptLocalStorage()) {
      this.router.navigate(['yajman-address', encodeURI(`${id}/${idval}`)]);
    }

  }
  viewInclusionPkg() {
    this.poojaservice.getPkgInclusion().subscribe((res: any) => {
      this.allInclusion = res.data;
      this.allInclusion = this.allInclusion.reverse()
      const suggestedMuhurtData = res.data[0].suggestedmuhurtaNode;
      if (Array.isArray(suggestedMuhurtData)) {
        this.allInclusion = suggestedMuhurtData;
      }
    
    })
  }
 
  getcheckValue(value: any, index: any) {

    if (value.checked) {
      let item = this.allInclusion.find((elem: any) => elem.inclusion_name === value.value)
      this.allPkg[index].package_price = this.allPkg[index].package_price + item.price
      this.cryptojs.encryptLocalStoragePV( this.allPkg[index].package_price)
    } else {
      let item = this.allInclusion.find((elem: any) => elem.inclusion_name === value.value)
      this.allPkg[index].package_price = this.allPkg[index].package_price - item.price
      this.cryptojs.encryptLocalStoragePV( this.allPkg[index].package_price)
    }
  }
  updateMetaTags(){
    this.meta.updateTag(
      { name: 'description', content: '' }, 
    );
    this.meta.updateTag(
      { name: 'keywords', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:title', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:determiner', content: '' }, 
    );
    
  }

}


