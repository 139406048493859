import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PoojaService } from 'src/app/service/pooja.service';
import { CryptojsService } from 'src/app/service/cryptojs.service';
@Component({
  selector: 'app-astrology',
  templateUrl: './astrology.component.html',
  styleUrls: ['./astrology.component.scss']
})
export class AstrologyComponent {
  bookingDetailsConsultancy: any


  constructor(private poojaService: PoojaService, private toastr: ToastrService,private cryptojs : CryptojsService) {
    this.getconsultancyDetails()
  }

  ngOnInit(): void {


  }
  getconsultancyDetails() {
    const userId = this.cryptojs.decryptLocalStorage()
    if (userId) {
      this.poojaService.getBookingConsultancyDetails(userId).subscribe(
        (res: any) => {
          this.bookingDetailsConsultancy = res.data;
          // Update the status based on pooja_date
          const currentDate = new Date(); // Current date and time

          this.bookingDetailsConsultancy.forEach((booking: any) => {
            const poojaDate = new Date(booking.pooja_date);
            if (poojaDate < currentDate) {
              booking.status = "completed";
            } else {
              booking.status = booking.status;
            }
          });
          this.bookingDetailsConsultancy.reverse();

        },
        (error) => {
          console.error('Error bookingDetailsConsultancy:', error);
        }
      );
    } else {
    }
  }
  cancelBooking(booking: any) {
    this.poojaService.cancelYajmanConsultancy(booking.id).subscribe(
      (res: any) => {
        // Update the status to "cancel" in the bookingDetails array
        booking.status = "cancel";
        this.toastr.success("Booking Cancelled Successfully")
      },
      (error) => {
        console.error('Error cancelling booking:', error);
      }
    );
  }

}

