import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  // headeridobs = new BehaviorSubject<any>(null);
  // baseURL: any = 'http://www.api.vedangshastra.com';
  // baseURL: any = 'https://api.vedangshastra.com'
  baseURL: any = environment.apiurl
  selectedPooja = new BehaviorSubject<{ data: any, id: any }>({ data: null, id: null });

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': 'true',
      'Access-Control-Allow-Origin': '*'
    }),
  };

  userLogin = new BehaviorSubject<any>(false)

  constructor(private http: HttpClient) { }

  setPooja(data: any, id: any) {
    this.selectedPooja.next({ data: data, id: id });
  }

  postContactus(formData: any): Observable<any> {
    return this.http.post(this.baseURL + `/api/v1/contactUs/submit`, formData, this.httpOptions)
  }

  verifyOtp(): Observable<any> {
    return this.http.get(this.baseURL)
  }

  userRegister(): Observable<any> {
    return this.http.get(this.baseURL)
  }

  resendOtp(): Observable<any> {
    return this.http.get(this.baseURL)
  }

  sendOtpByEmail(email: any): Observable<any> {
    return this.http.post(this.baseURL + `/api/v1/user/send?email=${email}`, this.httpOptions);
  }

  verifyOtpByEmail(email: string, otp: string): Observable<any> {
    const url = `${this.baseURL}/api/v1/user/login?email=${(email)}&otp=${otp}`;
    return this.http.post(url, null, this.httpOptions);
  }








}
