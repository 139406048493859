import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-details-of-list',
  templateUrl: './details-of-list.component.html',
  styleUrls: ['./details-of-list.component.scss']
})
export class DetailsOfListComponent {
  constructor(private activateroute:ActivatedRoute){
    this.activateroute.paramMap.subscribe((res:any) => {
    })
  }
}
