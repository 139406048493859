<div class="page-container">
<app-header></app-header>


    <div class="main-content" style="background-color: #FFE7C5;"> 
        <!-- <section class="section-404 section-lg-space"> -->
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center mb-4">
                        <div class="image-404">
                            <img src="assets/images/pagenotfound.png" class="w-100" alt="">
                        </div>
                    </div>
                
         
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center d-flex align-items-center justify-content-center" >
                        <div class="contain-404">
                            <h3 class="text-content mb-3 pg-nt-found">Page Not Found</h3>
                            <h3 class="err-404">ERROR 404</h3>
                            <button routerLink="/">Back to home screen</button>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </section>    -->
        <!-- <div class="container" style="display: flex; justify-content: center; height:600px">
            <img src="assets/images/page_not_found.png">
        </div> -->
    </div>

<app-footer></app-footer>
</div>