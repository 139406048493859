import { DatePipe } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router, RouterLink } from '@angular/router';
import { Modal } from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConsultancyService } from 'src/app/service/consultancy.service';
import { PoojaService } from 'src/app/service/pooja.service';
import { CryptojsService } from 'src/app/service/cryptojs.service';
import { Meta } from '@angular/platform-browser';

declare var Razorpay: any;

@Component({
  selector: 'app-yajman-address',
  templateUrl: './yajman-address.component.html',
  styleUrls: ['./yajman-address.component.scss'],
  providers: [DatePipe]
})
export class YajmanAddressComponent implements OnInit, OnDestroy {
  yajmanaddressForm!: FormGroup;
  allPkg: any = [];
  yajman: any = [];
  poojaDetails: any
  id: any;
  yajmanid: any
  poojaPrice: any = this.cryptojs.decryptLocalStoragePV();
  packageId: any;
  remainingValue: number = 0;

  getAddressValue: any = { customerName: 'NA', phoneNo: 'NA', addressLine1: 'NA', };
  submitted: boolean = false;
  priceDifference: any = 0
  concatAddr: any
  concatFullname: any;
  mobileNo: any;
  adVanceAmnt: any = 0;
  adVanceAmntOne: any = 0;
  finalTotalamnt: any = 0
  currenturll: any;
  consultancydetails: any = [];

  package_price: any;
  booking_amount: any;
  condition: boolean = false;
  citylist: any = []
  citylistTwo: any = []
  statelist: any = []
  countrylist: any = []
  stateVisible: boolean = false;
  countryVisible: boolean = false;
  cityVisible: boolean = false;
  yajmanIdentity: any;
  consultancy_yajman_id: any;
  hideAdvanceRemaining: boolean = false;
  hideConsultancyAdvanceRemaining: boolean = false;
  modalRequest: any = 0
  astroHide: boolean = false;
  vastuHide: boolean = false;
  consultancyData: any;
  showConsultancyName: boolean = true;
  checkedVastu: boolean = false;
  getConsultancy: any;
  //local yajman email
  yajmanEmailSend: any = ''


  constructor(private formBuilder: FormBuilder, private poojaService: PoojaService, private router: ActivatedRoute, private routertwo: Router
    , private datepipe: DatePipe, private ngZone: NgZone, private routeree: Router, 
    private consultancyservice: ConsultancyService, private toaster: ToastrService,private cryptojs : CryptojsService,private meta: Meta) {
    this.currenturll = this.routeree.url
    this.id = this.router.snapshot.params['id'];
    this.routertwo.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
      }
    });


    this.poojaService.yajmanEmail.subscribe((res: any) => {
      this.yajmanEmailSend = res
    })

  }




  ngOnInit() {

    this.yajmanaddressForm = this.formBuilder.group({

      gotra: [''],
      kuldevta: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      pooja_date: ['', Validators.required],
      // mobile_no: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.pattern(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)]],
      country: [{ value: 'India', disabled: true }, Validators.required],
      state: [{ value: 'Maharashtra', disabled: true }, Validators.required],
      city: ['', Validators.required],
      flatno: ['', Validators.required],
      area: ['', Validators.required],
      landmark: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      mobile_no: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]]
    })

    if (this.currenturll.includes('frompackage')) {
      this.orderSummary()
      this.condition = false;
    } else {
      this.consultancyDetails()
      this.condition = true;
    }

    this.getState()
    this.getCountry()
    this.poojaService.poojaOrConsultancy.subscribe((res: any) => {

      this.modalRequest = res
      if (this.modalRequest == "1") {
        this.showConsultancyName = false;
      } else {
        this.showConsultancyName = true;
      }
    })

    this.poojaService.consultancyHideField.subscribe((res: any) => {
      this.getConsultancy = res
      if (localStorage.getItem("checkPorC") == "pooja") {
        this.modalRequest = 0
      } else if (localStorage.getItem("checkPorC") == "consultancy") {
        this.modalRequest = 1
      } else {
        //  this.toaster.error("not found pooja and consultancy Something goes wrong, Error")
      }


      if (localStorage.getItem("consultancy_name") == "Vastu") {
        this.getConsultancy = 1
      } else if (localStorage.getItem("consultancy_name") == "Astrology") {
        this.getConsultancy = 0
      } else {
        //  this.toaster.error(" Consultancy not find Something goes wrong, Error")
      }

      if (this.modalRequest == "1" && this.getConsultancy == "0") {

        let fieldArray = ['country', 'state', 'city', 'flatno', 'area', 'landmark', 'pincode']
        for (let i = 0; i <= fieldArray.length - 1; i++) {
          this.yajmanaddressForm.get(fieldArray[i])?.clearValidators();
          this.yajmanaddressForm.get(fieldArray[i])?.updateValueAndValidity();
        }
        this.astroHide = true;
        this.vastuHide = false;

      } else if (this.modalRequest == "1" && this.getConsultancy == "1") {
        this.vastuHide = true;
        this.astroHide = false;

      } else {
        // this.toaster.error("Something goes wrong, Error")
      }
    })



    this.updateMetaTags()

    this.getradioValueConsultancy(this.cryptojs.decryptLocalStorageCBA(), "advanaceradio")
    this.poojaService.local_yajman_id.subscribe((res: any) => {
      if (res) {
        this.yajmanaddressForm.value.id = res
      }
    })
    this.poojaService.local_yajman_consultancy_id.subscribe((res: any) => {
      if (res) {
        this.yajmanaddressForm.value.id = res
      }
    })

    this.citylistTwo = [
      "Airoli", "Ambernath", "Ambivili", "Andheri", "Asangaon", "Atgaon", "Badlapur",
      "Bamandongri", "Bandra", "Belapur CBD", "Bhandup", "Bhayander", "Bhivpuri",
      "Borivali", "Byculla", "Charni Road", "Chembur", "Chinchpokli", "Chunabhatti",
      "Churchgate", "Cotton Green", "Currey Road", "Dadar", "Dahisar", "Diva",
      "Dockyard Road", "Dolavali", "Dombivli", "Dronagiri", "Elphinstone/Prabhadevi",
      "Gavhan", "Ghansoli", "Ghatkopar", "Goregaon", "Govandi", "Grant Road",
      "GTB Nagar", "Jogeshwari", "Juinagar", "Kalwa", "Kalyan", "Kandivali", "Kanjurmarg",
      "Karjat", "Kasara", "Kelavali", "Khadavli", "Khandeshwar", "Khar Road", "Khardi",
      "Kharghar", "Kharkopar", "Khopoli", "Kings Circle", "Kopar", "Koparkhairne", "Kurla",
      "Lower Parel", "Lowjee", "Mahalakshmi", "Mahim", "Malad", "Mankhurd", "Mansarovar",
      "Marine Lines", "Masjid", "Matunga", "Matunga Road", "Mira Road", "Mulund",
      "Mumbai Central", "Mumbai CST", "Mumbra", "Nahur", "Naigaon", "NallaSopara",
      "Neral", "Nerul", "Nhava Sheva", "Palasdari", "Panvel", "Parel", "Rabale",
      "Ram Mandir", "Ranjanpada", "Reay Road", "Sagar Sangam", "Sandhurst Road", "Sanpada",
      "Santacruz", "Seawood Darave", "Sewri", "Shahad", "Shelu", "Sion", "Targhar",
      "Thakurli", "Thane", "Tilaknagar", "Titwala", "Turbhe", "Ulhasnagar", "Uran City",
      "Vangani", "Vasai Road", "Vashi", "Vasind", "Vidhyavihar", "Vikhroli", "Vile Parle",
      "Virar", "Vitthalwadi", "Wadala Rd"
    ]

    document.getElementById("defaultClick")?.click()


  }

  getCountry() {
    var headers = new Headers();
    headers.append("X-CSCAPI-KEY", "Y21qelgyakRacXFBdUF4OEVTWHl5eExFdzNMZDVEWmVtbkZ3QXBBRQ==");

    var requestOptions: any = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    };
    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then(response => response.text())
      .then(result =>
        this.countrylist = JSON.parse(result)
      )
      .catch(error => console.log('error', error));

    setTimeout(() => {

      if (this.countrylist.length > 0) {
        this.countryVisible = true;
      } else {
        this.countryVisible = false;
      }
    }, 1500)
  }


  getState() {
    var headers = new Headers();
    headers.append("X-CSCAPI-KEY", "Y21qelgyakRacXFBdUF4OEVTWHl5eExFdzNMZDVEWmVtbkZ3QXBBRQ==");

    var requestOptions: any = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    };

    fetch("https://api.countrystatecity.in/v1/countries/IN/states", requestOptions)
      .then(response => response.text())
      .then(result =>
        this.statelist = JSON.parse(result)
      )
      .catch(error => console.log('error', error));

    setTimeout(() => {

      if (this.statelist.length > 0) {
        this.stateVisible = true;
      } else {
        this.stateVisible = false;
      }
    }, 1500)

  }


  getCities(statecode: any) {
    var bac = this.statelist.filter((val: any) => {
      return val.name.includes(statecode)
    })

    var headers = new Headers();
    headers.append("X-CSCAPI-KEY", "Y21qelgyakRacXFBdUF4OEVTWHl5eExFdzNMZDVEWmVtbkZ3QXBBRQ==");

    var requestOptions: any = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    };
    fetch(`https://api.countrystatecity.in/v1/countries/IN/states/${bac[0]?.iso2}/cities`, requestOptions)
      .then(response => response.text())
      .then(result =>
        this.citylist = JSON.parse(result)
      )
      .catch(error => console.log('error', error));

    setTimeout(() => {

      if (this.citylist.length > 0) {
        this.cityVisible = true;
      } else {
        this.cityVisible = false;
      }
    }, 700)

  }


  onSubmit() {
    this.poojaService.poojaOrConsultancy.next("0")
   
    this.poojaService.yajmanEmail.next(this.yajmanaddressForm.value.email)

    this.yajmanaddressForm.markAllAsTouched();

    if (this.yajmanaddressForm.valid) {

      this.poojaDetails = {
        ...this.poojaDetails,
        "booking_date": new Date(),
        "pooja_date": this.yajmanaddressForm.value.pooja_date,
        "user_id":this.cryptojs.decryptLocalStorage(),
      }

      this.yajmanaddressForm.value.user_id = this.cryptojs.decryptLocalStorage()
      this.yajmanaddressForm.value.package_id = localStorage.getItem("package_id")
      this.yajmanaddressForm.value.pooja_id = localStorage.getItem("pooja_Details")
      this.yajmanaddressForm.value.country = "India"
      this.yajmanaddressForm.value.state = "Maharashtra"

      this.poojaService.postYajmanAddress(this.yajmanaddressForm.value).subscribe(
        (data) => {
          this.poojaService.local_yajman_id.next(data.data.id)
          this.yajmanIdentity = data.data.id
          document.getElementById('proceedModal')?.click()
          const proceedModal = document.getElementById('proceedModal');
          if (proceedModal) {
            let modal = new Modal(proceedModal); // Use the imported Modal class
            modal.show();

          }
        },
        (error) => {

          this.toaster.error('Something goes wrong', 'Error');
        }
      );
    }


  }


  onSubmitConsultancy() {
    this.poojaService.yajmanEmail.next(this.yajmanaddressForm.value.email)
    if (this.astroHide) {
      this.checkedVastu = true
    }

    this.poojaService.poojaOrConsultancy.next(1)
    this.yajmanaddressForm.markAllAsTouched();

    if (this.yajmanaddressForm.valid) {
      this.poojaDetails = {
        ...this.poojaDetails,
        "booking_date": new Date(),
        "pooja_date": this.yajmanaddressForm.value.pooja_date,
        "user_id":this.cryptojs.decryptLocalStorage(),
      }
      this.yajmanaddressForm.value.user_id = this.cryptojs.decryptLocalStorage()
      this.yajmanaddressForm.value.package_id = localStorage.getItem("package_id")
      this.yajmanaddressForm.value.pooja_id = localStorage.getItem("pooja_Details")
      this.yajmanaddressForm.value.consultancy_yajman_id = localStorage.getItem("id")
      this.yajmanaddressForm.value.country = "India"
      this.yajmanaddressForm.value.state = "Maharashtra"
     
      this.consultancyservice.postConsultancyDetails(this.yajmanaddressForm.value).subscribe(
        (data) => {
          this.poojaService.local_yajman_consultancy_id.next(data.data.id)
          this.consultancy_yajman_id = data.data.id
          document.getElementById('proceedModal')?.click()
          const proceedModal = document.getElementById('proceedModal');
          if (proceedModal) {
            let modal = new Modal(proceedModal);
            modal.show();
          }
        },
        (error) => {
          this.toaster.error('Something goes wrong', 'Error');
        }
      );
    }
  }

  getTodayDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const tomorrowISOString = tomorrow.toISOString().split('T')[0];
    return tomorrowISOString;
  }

  packagePrice: any

  orderSummary() {
    const match = this.id.match(/\d+/);
    console.log(match,'matchhh');
    
    let tempid: any
    if (match) {
      tempid = match[0];
    }

    this.poojaService.getPackegeone(tempid).subscribe((res: any) => {
      console.log("get res data jayman",res.data.length);
      
      if (res.data.length > 0) {
        console.log("inside if",res.data);
        this.allPkg = res.data;
        this.getradioValue(res.data[0].booking_amount, 'advanaceradio')
        this.packagePrice = res['data'][0].package_price
        this.poojaDetails = {
          ...this.poojaDetails,
          "pooja_type": res['data'][0].poojaname,

          "amount": res['data'][0].package_price,
        }
      } else {
        console.log("inside else",res.data);
        
        this.routertwo.navigateByUrl("/notfound")
      }
    });
  }


  poojaPayment() {

    this.cryptojs.encryptLocalStoragePR(this.priceDifference)
    let priceDifference: any = this.cryptojs.decryptLocalStoragePR()
    let paidAmount = this.poojaPrice - priceDifference
    let data = {
      ...this.poojaDetails,
      'status': 'Booked',
      'package_amount':this.cryptojs.decryptLocalStoragePV(),
      'paid_amount': paidAmount,
      'remaining_amount': priceDifference,
      'yajman_id': this.yajmanIdentity,
      'booking_amount': paidAmount,
      //send email booked pooja
      'email': this.yajmanEmailSend
    }
    this.poojaService.bookPooja(data).subscribe((res: any) => {
      this.toaster.success("Pooja booked successfully");
      this.routertwo.navigate(['my-booking'])

    })

  }

  consultancyPay() {
    this.cryptojs.encryptLocalStoragePR(this.priceDifference)
    let priceDifference: any =this.cryptojs.decryptLocalStoragePR()
    let paidAmount = this.poojaPrice - priceDifference
    let data = {
      ...this.poojaDetails,
      'status': 'Booked',

      'package_amount':this.cryptojs.decryptLocalStorageCPP(),
      'paid_amount': paidAmount,
      'remaining_amount': priceDifference,

      'pooja_type': localStorage.getItem('consultancy_name'),

      'consultancy_yajman_id': this.consultancy_yajman_id,
      'booking_amount': this.cryptojs.decryptLocalStorageCBA(),
      //send email for booked consultnacy
      'email': this.yajmanEmailSend
    }
    this.poojaService.bookConsultancy(data).subscribe((res: any) => {
      this.toaster.success("Consultancy booked successfully");
      this.routertwo.navigate(['astrology'])

    })

  }

  //Proceed Payment
  paymentOrderSummary() {
    let checkPorC: any = localStorage.getItem("checkPorC")
    if (checkPorC == "pooja") {
      this.poojaPayment()
    } else if (checkPorC == "consultancy") {
      this.consultancyPay()
    } else {
      this.toaster.error("Something goes wrong")
    }

  }

  dateObj!: any;

  viewAll() {
    if (this.astroHide) {
      this.checkedVastu = true;
    }

    if (this.modalRequest == "0") {

      this.yajmanid =this.cryptojs.decryptLocalStorage()
      if (this.yajmanid) {
        this.poojaService.getYajmanDetails(this.yajmanid).subscribe((res: any) => {
          res.data.filter((obj: any) => {
            this.poojaService.local_yajman_id.subscribe((res: any) => {
              if (obj.id == res) {
                this.yajman = obj
              }
            })
          });

          this.dateObj = this.datepipe.transform(this.yajman.pooja_date, 'yyyy-MM-dd')

          this.yajmanaddressForm.patchValue({
            gotra: this.yajman.gotra,
            kuldevta: this.yajman.kuldevta,
            first_name: this.yajman.first_name,
            last_name: this.yajman.last_name,
            pooja_date: this.dateObj,
            mobile_no: this.yajman.mobile_no,
            email: this.yajman.email,
            country: this.yajman.country,
            state: this.yajman.state,
            city: this.yajman.city,
            flatno: this.yajman.flatno,
            area: this.yajman.area,
            landmark: this.yajman.landmark,
            pincode: this.yajman.pincode,
          });
        });
      } else {
      }
    } else {
      this.yajmanid =this.cryptojs.decryptLocalStorage()
      if (this.yajmanid) {
        this.poojaService.getYajmanConsultancyDetails(this.yajmanid).subscribe((res: any) => {
          res.data.filter((obj: any) => {
            this.poojaService.local_yajman_consultancy_id.subscribe((res: any) => {
              if (obj.id == res) {
                this.yajman = obj
              }
            })
          });
          this.yajmanaddressForm.patchValue({
            gotra: this.yajman.gotra,
            kuldevta: this.yajman.kuldevta,
            first_name: this.yajman.first_name,
            last_name: this.yajman.last_name,
            // pooja_date: this.yajman.pooja_date,
            pooja_date: this.datepipe.transform(this.yajman.pooja_date, 'yyyy-MM-dd'),
            mobile_no: this.yajman.mobile_no,
            email: this.yajman.email,
            country: this.yajman.country,
            state: this.yajman.state,
            city: this.yajman.city,
            flatno: this.yajman.flatno,
            area: this.yajman.area,
            landmark: this.yajman.landmark,
            pincode: this.yajman.pincode
          });
        });

      } else {

      }
    }
  }


  updateAddress() {
    this.poojaService.yajmanEmail.next(this.yajmanaddressForm.value.email)

    if (this.modalRequest == "0") {
      this.yajmanaddressForm.controls['id']?.setValue(this.id)
      this.yajmanaddressForm.value.country = "India"
      this.yajmanaddressForm.value.state = "Maharashtra"
      this.poojaService.local_yajman_id.subscribe((res: any) => {
        this.yajmanaddressForm.value.id = res
      })
      this.yajmanaddressForm.value.user_id = this.cryptojs.decryptLocalStorage()
      if (this.yajmanaddressForm.valid) {
        this.poojaService.updateYajmanDetails(this.id, this.yajmanaddressForm.value).subscribe((res: any) => {
          this.concatFullname = res.data.first_name + " " + res.data.last_name
          this.concatAddr = res.data.flatno + "," + res.data.area + "," + res.data.landmark + "," + res.data.city + "," + res.data.pincode
          this.mobileNo = res.data.mobile_no
          //poojaDetails => pooja
          this.poojaDetails = {
            ...this.poojaDetails,
            "booking_date": new Date(),
            "pooja_date": this.yajmanaddressForm.value.pooja_date,
            "user_id": this.cryptojs.decryptLocalStorage()
          }
          this.toaster.success("Form updated successfully");
          const proceedModal = document.getElementById('proceedModal');
          if (proceedModal) {
            let modal = new Modal(proceedModal); // Use the imported Modal class
            modal.show();
          }

        })
      } else {
        this.toaster.warning("Please enter valid data!")
        this.reopenUpdateModal()
      }

    } else {
      this.yajmanaddressForm.controls['id']?.setValue(this.id)
      this.yajmanaddressForm.value.country = "India"
      this.yajmanaddressForm.value.state = "Maharashtra"
      this.poojaService.local_yajman_consultancy_id.subscribe((res: any) => {
        this.yajmanaddressForm.value.id = res
      })
      this.yajmanaddressForm.value.user_id =this.cryptojs.decryptLocalStorage()
      if (this.yajmanaddressForm.valid) {
        this.poojaService.updateYajmanConsultancyDetails(this.id, this.yajmanaddressForm.value).subscribe((res: any) => {
          this.concatFullname = res.data.first_name + " " + res.data.last_name
          this.concatAddr = res.data.flatno + "," + res.data.area + "," + res.data.landmark + "," + res.data.city + "," + res.data.pincode
          this.mobileNo = res.data.mobile_no

          //poojaDetails => consultancy
          this.poojaDetails = {
            ...this.poojaDetails,
            "booking_date": new Date(),
            "pooja_date": this.yajmanaddressForm.value.pooja_date,
            "user_id": this.cryptojs.decryptLocalStorage(),
          }

          this.toaster.success("Form updated successfully");

          //open modal again
          const proceedModal = document.getElementById('proceedModal');
          if (proceedModal) {
            let modal = new Modal(proceedModal); // Use the imported Modal class
            modal.show();
          }
        })
      } else {
        this.toaster.warning("Please enter valid data!")
        this.reopenUpdateModal()
      }


    }
  }


  //ant23
  reopenUpdateModal() {
    const exampleModalToggle2 = document.getElementById('exampleModalToggle2');
    if (exampleModalToggle2) {
      let modal = new Modal(exampleModalToggle2); // Use the imported Modal class
      modal.show();

    }

  }

  reopenPaymentModal() {
    const proceedModal = document.getElementById('proceedModal');
    if (proceedModal) {
      let modal = new Modal(proceedModal); // Use the imported Modal class
      modal.show();

    }
  }

  get f() {
    console.log("get data*******", this.yajmanaddressForm.controls);
    
    return this.yajmanaddressForm.controls;
  }
  getDetailsFOrprocedd() {
    let getflat = this.yajmanaddressForm.controls['flatno'].value;
    let getarea = this.yajmanaddressForm.controls['area'].value;
    let getlandmark = this.yajmanaddressForm.controls['landmark'].value;
    let getcity = this.yajmanaddressForm.controls['city'].value;
    let getpincode = this.yajmanaddressForm.controls['pincode'].value;
    this.concatAddr = `${getflat} ${getarea} ${getlandmark} ${getcity}  ${getpincode}`
    let getname = this.yajmanaddressForm.controls['first_name'].value;
    let getlastName = this.yajmanaddressForm.controls['last_name'].value;
    this.concatFullname = `${getname} ${getlastName}`
    let phone = this.yajmanaddressForm.controls['mobile_no'].value;
    this.mobileNo = `${phone}`
  }

  getradioValue(value: any, radioname: any) {
    if (radioname == 'advanaceradio') {
      this.adVanceAmnt = value
      let totalValue =this.cryptojs.decryptLocalStoragePV() || '0';
      this.priceDifference = parseInt(totalValue) - value
      this.hideAdvanceRemaining = false
      this.checkedVastu = false;
    } else if (radioname == 'fullpaymnetradio') {
      this.hideAdvanceRemaining = true
      this.checkedVastu = true;
      this.adVanceAmnt = 0
      this.priceDifference = 0
    }
  }

  consultancyDetails() {
    const match = this.id.match(/\d+/); // Regular expression to match numbers
    let tempid: any
    if (match) {
      tempid = match[0]; // Get the first match
    }
    this.consultancyservice.getConsultancyDetails(tempid).subscribe((res: any) => {
      this.consultancydetails = res.data;
      this.consultancyData = res.data[0]
      this.consultancydetails.forEach((ele: any) => {
        this.package_price = ele.package_price
        this.booking_amount = ele.booking_amount
      })
    });
  }

  getradioValueConsultancy(value: any, radioname: any) {
    if (radioname == 'advanaceradio') {
      this.adVanceAmnt = value
      let totalValue = this.cryptojs.decryptLocalStorageCPP() || '0';
      this.priceDifference = parseInt(totalValue) - value
      this.hideConsultancyAdvanceRemaining = false;
      this.checkedVastu = false;
    } else if (radioname == 'fullpaymnetradio') {
      this.adVanceAmnt = 0
      this.priceDifference = 0
      this.hideConsultancyAdvanceRemaining = true;
      this.checkedVastu = true;
    }
  }
  validateAlphabet(e: KeyboardEvent): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (
      (charCode >= 65 && charCode <= 90) || // Uppercase letters (A-Z)
      (charCode >= 97 && charCode <= 122) || // Lowercase letters (a-z)
      charCode === 32 || // Space
      charCode === 45 || // Hyphen
      charCode === 39 // Apostrophe (e.g., O'Neill)
    ) {
      return true;
    } else {
      e.preventDefault(); // Prevent input of invalid characters
      return false;
    }
  }
  validateMobileNumber(e: KeyboardEvent) {
    const charCode = e.which ? e.which : e.keyCode;
    if ((charCode >= 65 && charCode <= 90) || // Uppercase letters (A-Z)
      (charCode >= 97 && charCode <= 122) || // Lowercase letters (a-z)
      charCode === 32 || // Space
      charCode === 45 || // Hyphen
      charCode === 39) {
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  updateMetaTags(){
    this.meta.updateTag(
      { name: 'description', content: '' }, 
    );
    this.meta.updateTag(
      { name: 'keywords', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:title', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:determiner', content: '' }, 
    );
    
  }

  ngOnDestroy() {
    localStorage.setItem("checkPorC", '')
  }


}





