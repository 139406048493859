import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/service/global.service';
import { PoojaService } from 'src/app/service/pooja.service';
@Component({
  selector: 'app-list-of-pooja',
  templateUrl: './list-of-pooja.component.html',
  styleUrls: ['./list-of-pooja.component.scss']
})
export class ListOfPoojaComponent implements OnInit {
  allPooja: any = [];
  imagePaths: any
  imageUrls: string[] = [];
  imgUrl: any = 'https://api.vedangshastra.com'


  constructor(private globalService: GlobalService, private router: Router, private poojaservice: PoojaService,private meta: Meta) {
  }
  ngOnInit(): void {
    this.poojaservice.getallPooja().subscribe((res: any) => {
      this.allPooja = res.data;
    });
    this.viewAll
    this.updateMetaTags()
  }
  getImageUrl(imagepath: string): string {
    if (imagepath && imagepath !== "null") {
      const imageUrls = imagepath.split(',');
      return `${this.imgUrl}${imageUrls[0].trim()}`;
    } else {
      return '';

    }

  }

  viewAll(pooja_category_id: any) {
    this.globalService.selectedPooja.next(pooja_category_id); // Use next to set the value
    this.router.navigate(['pooja', pooja_category_id]);
  }

  bookNow(poojaId: any) {
    this.globalService.selectedPooja.next(poojaId); // Use next to set the value
    this.router.navigate(['pooja-details', poojaId]);
  }
  updateMetaTags(){
    this.meta.updateTag(
      { name: 'description', content: '' }, 
    );
    this.meta.updateTag(
      { name: 'keywords', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:title', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:determiner', content: '' }, 
    );
    
  }


}


