import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptojsService {

  constructor() { }


  
  // secure data functions : encrypt and decrypt

  secretKey = "Pass@123#"

  encrypt(data: string) {
    return CryptoJS.AES.encrypt(data, "Pass@123#").toString();
  }

  decrypt(data: string) {
    const decryptedBytes = CryptoJS.AES.decrypt(data, "Pass@123#");
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
  }

  decryptLocalStorage() {
    if(localStorage.getItem("UserId") == null){
      return null
    }else{
      let localData = localStorage.getItem("UserId") || '';
      const decryptedBytes = CryptoJS.AES.decrypt(localData, "Pass@123#")
      let data = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(data)
    }
    

  
  }

  encryptLocalStorage(data: any) {
    if (typeof data == typeof 'String') {
      let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
      localStorage.setItem("UserId", encryptedData)
    } else {
      data = JSON.stringify(data)
      let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
      localStorage.setItem("UserId", encryptedData)
    }
  }

  decryptLocalStoragePV() {
    let localData = localStorage.getItem("poojaValue") || '';
    const decryptedBytes = CryptoJS.AES.decrypt(localData, "Pass@123#")
    let data = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(data)
  }

  encryptLocalStoragePV(data: any) {
    if (typeof data == typeof 'String') {
      let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
      localStorage.setItem("poojaValue", encryptedData)
    } else {
      data = JSON.stringify(data)
      let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
      localStorage.setItem("poojaValue", encryptedData)
    }
  }

  //get 
  decryptLocalStoragePA() {
    let localData = localStorage.getItem("packageAmount") || '';
    const decryptedBytes = CryptoJS.AES.decrypt(localData, "Pass@123#")
    let data = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(data)
  }

  //set
  encryptLocalStoragePA(data: any) {
    if (typeof data == typeof 'String') {
      let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
      localStorage.setItem("packageAmount", encryptedData)
    } else {
      data = JSON.stringify(data)
      let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
      localStorage.setItem("packageAmount", encryptedData)
    }
  }


    //get 
    decryptLocalStoragePR() {
      let localData = localStorage.getItem("remaining") || '';
      const decryptedBytes = CryptoJS.AES.decrypt(localData, "Pass@123#")
      let data = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(data)
    }
  
    //set
    encryptLocalStoragePR(data: any) {
      if (typeof data == typeof 'String') {
        let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
        localStorage.setItem("remaining", encryptedData)
      } else {
        data = JSON.stringify(data)
        let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
        localStorage.setItem("remaining", encryptedData)
      }
    }


        //get 
        decryptLocalStorageCPP() {
          let localData = localStorage.getItem("consultancypackage_price") || '';
          const decryptedBytes = CryptoJS.AES.decrypt(localData, "Pass@123#")
          let data = decryptedBytes.toString(CryptoJS.enc.Utf8);
          return JSON.parse(data)
        }
      
        //set
        encryptLocalStorageCPP(data: any) {
          if (typeof data == typeof 'String') {
            let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
            localStorage.setItem("consultancypackage_price", encryptedData)
          } else {
            data = JSON.stringify(data)
            let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
            localStorage.setItem("consultancypackage_price", encryptedData)
          }
        }


           //get 
           decryptLocalStorageCBA() {
            let localData = localStorage.getItem("booking_amount") || '';
            const decryptedBytes = CryptoJS.AES.decrypt(localData, "Pass@123#")
            let data = decryptedBytes.toString(CryptoJS.enc.Utf8);
            return JSON.parse(data)
          }
        
          //set
          encryptLocalStorageCBA(data: any) {
            if (typeof data == typeof 'String') {
              let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
              localStorage.setItem("booking_amount", encryptedData)
            } else {
              data = JSON.stringify(data)
              let encryptedData = CryptoJS.AES.encrypt(data, "Pass@123#").toString();
              localStorage.setItem("booking_amount", encryptedData)
            }
          }
  
}
