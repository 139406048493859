<app-header></app-header>
<div class="main-content">
    <!-- *ngIf="popularpooja" -->
    <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
        <div class="container breadcrumb-container">
            <ol class="breadcrumb">
                <li class="breadcrum-title">{{poojaname}}</li>
                <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img src="assets/icons/home-icon.png"
                            class="" alt=""> </a></li>
                <li class="breadcrumb-item active" aria-current="page">{{poojaname}}</li>

            </ol>
        </div>
    </nav>
    <section class="categoryPoojaModule">
        <div class="container">
            <h3 class="section-heading">{{poojaname}}</h3>
            <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4" *ngFor="let option of poojaData;">
                    <div class="poojaCard">
                        <div class="pooja-card">
                            <div class="img">
                                <img [src]="getImageUrl(option.imagepath)" alt=""
                                    (click)="bookNowPooja(option.id.toString())">
                            </div>
                        </div>
                        <div class="cardFooter mt-2">
                            <h3 class="title mb-3" (click)="bookNowPooja(option.id.toString())">{{option.name}}</h3>
                            <div class="d-flex justify-content-between align-items-center">
                                <h2 class="price">₹ {{option.booking_amount}}</h2>
                                <button (click)="bookNowPooja(option.id.toString())">Book Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>