import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/service/global.service';
import { ToastrService } from 'ngx-toastr';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsForm!: FormGroup;
  submitted: boolean = false;
   
  

  constructor(private formBuilder: FormBuilder, private globalService: GlobalService, private toaster: ToastrService,private meta: Meta) { }

  ngOnInit(): void {
    this.contactUsForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobilenumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      // mobilenumber: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      email: ['', [Validators.required, Validators.pattern(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)]], // Validate email format
      message: ['', Validators.required],
      // privacyPolicy: ['', Validators.required], // Assuming it's a checkbox for privacy policy
    });
    this.updateMetaTags()
  }

  onSubmit() {
    this.contactUsForm.markAllAsTouched();
    if (this.contactUsForm.valid) {
      this.globalService.postContactus(this.contactUsForm.value).subscribe((res: any) => {
        if (res) {
          this.toaster.success("Form submited successfully");
          this.contactUsForm.reset()
        } else {
          this.toaster.error('Something goes wrong', 'Error');
        }
      });
    }
  }
  validateAlphabet(e: KeyboardEvent): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (
      (charCode >= 65 && charCode <= 90) || // Uppercase letters (A-Z)
      (charCode >= 97 && charCode <= 122) || // Lowercase letters (a-z)
      charCode === 32 || // Space
      charCode === 45 || // Hyphen
      charCode === 39 // Apostrophe (e.g., O'Neill)
    ) {
      return true;
    } else {
      e.preventDefault(); // Prevent input of invalid characters
      return false;
    }
  }
  validateMobileNumber(e: KeyboardEvent): boolean {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      const inputValue: string = (e.target as HTMLInputElement).value + String.fromCharCode(charCode);
      if (inputValue.length <= 10) {
        return true;
      }
    }

    e.preventDefault(); // Prevent input of invalid characters or exceeding 10 characters
    return false;
  }
  updateMetaTags(){
    this.meta.updateTag(
      { name: 'description', content: '' }, 
    );
    this.meta.updateTag(
      { name: 'keywords', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:title', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:determiner', content: '' }, 
    );
    
  }
  
  

}
