import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './components/home/home.component';
import { ListOfPoojaComponent } from './components/list-of-pooja/list-of-pooja.component';
import { MuhurtComponent } from './components/muhurt/muhurt.component';
import { PackagesComponent } from './components/packages/packages.component';
import { PoojaDetailsComponent } from './components/pooja-details/pooja-details.component';
import { YajmanAddressComponent } from './components/yajman-address/yajman-address.component';
import { DetailsOfListComponent } from './components/details-of-list/details-of-list.component';
import { VastuComponent } from './components/consultancy/vastu/vastu.component';
import { AstrologyComponent } from './components/consultancy/astrology/astrology.component';
import { MyBookingComponent } from './components/my-booking/my-booking.component';
import { PoojaComponent } from './shared/pooja-dropdown/pooja/pooja.component';
import { CancellationPolicyComponent } from './components/cancellation-policy/cancellation-policy.component';
import { TermsNdConditionComponent } from './components/terms-nd-condition/terms-nd-condition.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { yajmanFormsGuard } from './service/yajman-forms.guard';
import { NotfoundComponent } from './components/notfound/notfound.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'list-of-pooja', component: ListOfPoojaComponent },
  { path: 'muhurt', component: MuhurtComponent },
  // {path:'packages',component:PackagesComponent},
  { path: 'packages/:id', component: PackagesComponent },
  { path: 'pooja-details/:id', component: PoojaDetailsComponent },
  { path: 'yajman-address', component: YajmanAddressComponent },
  { path: 'yajman-address/:id', canActivate: [yajmanFormsGuard], component: YajmanAddressComponent },
  { path: 'details-of-list', component: DetailsOfListComponent },
  { path: 'vastu/:id', component: VastuComponent },
  { path: 'astrology', component: AstrologyComponent },
  { path: 'my-booking', component: MyBookingComponent },
  { path: 'pooja', component: PoojaComponent },
  { path: 'pooja/:id', component: PoojaComponent },
  { path: 'cancellation-policy', component: CancellationPolicyComponent },
  { path: 'terms-nd-condition', component: TermsNdConditionComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],

  exports: [RouterModule]
})

export class AppRoutingModule { }
