<app-header></app-header>
<div class="page-container">
    <div class="main-content" *ngFor="let item of consultancydetails">

        <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
            <div class="container breadcrumb-container">
                <ol class="breadcrumb">
                    <li class="breadcrum-title">{{item.consultancy_name}}</li>
                    <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img
                                src="assets/icons/home-icon.png" class="" alt=""> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{item.consultancy_name}}</li>
                </ol>
            </div>
        </nav>

        <section class="astrologyModule">
            <div class="container">
                <h3 class="section-heading text-start mb-3">{{item.consultancy_name}}</h3>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="astrologyImgBlock mb-4">
                            <img [src]="getImageUrl(item.imagepath)" alt="">
                        </div>
                        <p *ngFor="let line of description_lines; let i = index">
                            {{ line }}
                        </p>
                    </div>
                    <div class="col-lg-4 sticky">

                        <div class="packageCard">
                            <div class="text-center">
                                <span class="tag">Package</span>
                                <h1 class="mt-3 mb-2">₹ {{item.package_price}}/-</h1>
                            </div>
                            <div class="package-card-list">
                                <p class="proceInvo">Procedure Involved</p>
                                <ul class="tick-li">
                                    <li *ngFor="let option of consultancy_procedure_options">
                                        <img src="assets/icons/check-icon.png" alt="">
                                        {{ option }}
                                    </li>
                                </ul>
                                <p class="inclusion">Inclusions</p>
                                <ul class="tick-li">
                                    <li><img src="assets/icons/check-icon.png" alt="">{{item.consultancy_inclusion}}
                                    </li>

                                </ul>
                            </div>
                            <div class="note mt-3">
                                <p><b>Note:</b> {{item.note}}</p>
                            </div>

                            <!--user login-->
                            <div class="btn-container mt-3" [hidden]="userLogin">
                                <button (click)="viewPackage(item.id.toString(),'fromconsultant')">Book Consultation
                                </button>
                            </div>

                            <!--user not login-->
                            <div class="btn-container mt-3" [hidden]="!userLogin">
                                <button data-bs-toggle="modal" data-bs-target="#loginModal">Book Consultation</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>

    </div>
    <app-footer></app-footer>

</div>