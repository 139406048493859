<app-header></app-header>
<div class="main-content">
    <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
        <div class="container breadcrumb-container">
            <ol class="breadcrumb">
                <li class="breadcrum-title">About us</li>
                <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img src="assets/icons/home-icon.png"
                            class="" alt=""> </a></li>
                <li class="breadcrumb-item active" aria-current="page">About us</li>
            </ol>
        </div>
    </nav>
    <section class="aboutModule">
        <div class="container">
            <div class="centerBlock">
                <h5>About us</h5>
                <h1>Welcome to Vedang Shastra</h1>
            </div>
            <div class="row">
                <div class="col-xxl-8 col-xl-8 col-lg-8">
                    <p>
                        Vedang Shastra is an online pooja booking portal in India where an attempt has been made to
                        strike a golden balance between technology and tradition. All our Pandits / Priests are trained in
                        certified Vedic Pathshalas and have experience in performing Pooja professionally. Vedang
                        Shastra offers best pooja services in urban India. <br> Book Pandit / Priest online with best
                        price for best puja ceremony. You can book Pandits / Priests for Pooja, Homhavan, Chant, Katha,
                        Patha, Marriage, Sakhpuda Ceremony, Pitru Dosha Pooja, Temple Seva and many other religious
                        rituals.
                    </p>
                    <b> Characteristics of Vedanga Shastra </b> <br>

                    1. Vedang Shastra dictates that all pujas / havans should be performed only and only on Muhurta.<br>
                    2. Every pandit who goes to the host's house for worship is checked for security reasons.<br>
                    3. Pandits / Priests of Vedang Shastra are certified to perform all the rituals in a Shasatrok
                    manner.<br>
                    4. Vedang Shastra is making life a step easier by offering Pooja / Havan Seva at home.<br>
                    5. Wants to ensure complete transparency in pricing by displaying Pooja / Havan prices directly on
                    the website.<br>
                    6. Vedang Shastra will encourage customers to post reviews online to ensure continuous improvement.

                    <div class="btn-container mt-4">
                        <button> <a routerLink="/contact-us">Contact us</a> </button>
                    </div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-4">
                    <div class="right-block-img">
                        <img src="assets/images/about-us.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>