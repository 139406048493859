import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/service/global.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PoojaService } from 'src/app/service/pooja.service';
import { ToastrService } from 'ngx-toastr';
import { finalize, take, timer } from 'rxjs';
import { ConsultancyService } from 'src/app/service/consultancy.service';
import { LoginService } from 'src/app/service/login.service';
import { CryptojsService } from 'src/app/service/cryptojs.service';
import { Modal } from 'bootstrap';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {
  @ViewChild("confimOTPCheckoutREF") confimOTPCheckoutREF!: ElementRef;
  @ViewChild("confimOTPREF") confimOTPREF!: ElementRef;

  successMessage: string | null = null;
  errorMessage: string | null = null;
  messageToShow: string = '';
  phoneNumber: any
  otpNumber: any
  showButton = true
  userinfo!: FormGroup;
  signupForm!: FormGroup;
  phoneNO: any;
  votp: boolean = false;
  pViaOtp: boolean = true;
  confimOtp: boolean = false;
  poojadd: any
  data: any = [];
  optSend: boolean = false;
  email: any
  otp: any
  timerCounter: number = 0;
  timerSubscription: any;
  isTimerActive: any;
  consultancies: any[] = [];
  signUp: any[] = []
  timerAndResend: boolean = false
  disableProceedViaOtp: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private globalService: GlobalService, private poojaService: PoojaService, private consultancyservice: ConsultancyService,
     private toaster: ToastrService, private loginservice: LoginService,private cryptojs : CryptojsService,private meta: Meta) {
    this.userinfo = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)]],
      otpno: ['', [Validators.maxLength(6)]],
    })
    this.signupForm = this.formBuilder.group({
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      mobileNumber: ''
    });
    //log out api not get response
    setTimeout(() => {
      this.poojaService.poojaListArray.subscribe((res: any) => {
        // console.log("after 5 sec");
        
        setTimeout(() => {
          // console.log("after 8 sec",res,res[0]?.names?.length);
          
          // console.log("get dsadsa",res[0]?.names?.length);
         
          if (res[0]?.names?.length > 0) {
            // console.log("1st if");
            
          }else {
            // console.log("1st else",this.cryptojs?.decryptLocalStorage());
            
            if(this.cryptojs.decryptLocalStorage()){
              this.logout()
              // console.log("1st else if");
              
            }else{
              // console.log("1st else else");
              this.toaster.error("Ooops!, server is down please try later")
            }
          }
        }, 8000)
      })
    }, 5000)
  }


  ngOnInit(): void {
    this.updateMetaTags()

    this.fetchPoojadropdown()
    this.consultancyDropDown()
    this.viewAll
    if (this.cryptojs.decryptLocalStorage()) {
      this.showLogout = true
    }

  }
  timer: boolean = true
  timeValue: number = 59
  resendOptTimer() {
    let timeINter = setInterval(() => {
      this.timeValue--
      if (this.timeValue == 55) {
        this.optSend = false
      }
      if (this.timeValue == 0) {
        this.timer = false
        clearInterval(timeINter)
      }
    }, 1000)
  }
  checkoutFlag: boolean = true
  getSelectedPooja(data: any, id: any) {
    this.globalService.setPooja(data, id);
    this.router.navigate(['/pooja', id]);
    this.listOfPooja()
  }

  listOfPooja() {
    this.poojaService.poojaOrConsultancy.next(0)
    localStorage.setItem("checkPorC", "pooja")
    localStorage.setItem("consultancy_name", "Pooja")
  }

  setLocal() {
    localStorage.setItem("checkPorC", "consultancy")
  }

  fetchPoojadropdown() {
    this.poojaService.getpoojaDropdown().subscribe((res: any) => {
      this.data = res.data
    })
  }

  getPhoneNo(data: any) {
    this.email = data.target.value
    if (this.userinfo.valid) {
      document.getElementById("btn-pro")?.classList.add("btn-active")
      this.disableProceedViaOtp = false;
    } else {
      document.getElementById("btn-pro")?.classList.remove("btn-active")
      this.disableProceedViaOtp = true;
    }
  }
  onInput(event: any) {
    const inputValue = event.target.value;
    const numericInput = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    event.target.value = numericInput;
  }

  validateNo(searchValue: any) {
    const charCode = searchValue.which ? searchValue.which : searchValue.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }


  disableOTP(otpValue: any) {
    if (this.userinfo.value.otpno && this.userinfo.value.otpno.length == 6) {
      this.showButton = false
    } else {
      this.showButton = true
    }
  }

  resendOTP() {
    this.optSend = true
    this.timeValue = 59
    this.timer = true
    this.resendOptTimer()
    this.proceedOtp()
  }


  newFunction() {
    if (this.disableProceedViaOtp) {
    } else {
      this.proceedOtp()
    }
  }

  proceedOtp() {
    this.optSend = true;
    this.votp = true;
    this.pViaOtp = false;
    this.confimOtp = true;
    this.timerAndResend = true;
    this.globalService.sendOtpByEmail(this.email).subscribe((res: any) => {
      this.resendOptTimer()
    })
  }

  showLogout: boolean = false

  confimOTPCheckout() {
    this.globalService.verifyOtpByEmail(this.email, this.userinfo.value.otpno).subscribe((res: any) => {
      if (res.status == 200) {
        this.cryptojs.encryptLocalStorage(res['data'].userData.id)
        this.showLogout = true;
        this.toaster.success('Login Successful');
        this.redirectTo(this.router.url)
        this.globalService.userLogin.next(true)
      }else{
        // this.toaster.error('Server is down please try later');
        // window.location.reload()
        const proceedModal = document.getElementById('loginModal');
          if (proceedModal) {
            let modal = new Modal(proceedModal);
            modal.show();
          }
        
      }

      if (res.status == 400) {
        document.getElementById("book")?.click()
        this.toaster.error('OTP Expired or Invalid');
        const otpControl = this.userinfo.get('otpno');
        if (otpControl) {
          otpControl.reset();
        }
        this.globalService.userLogin.next(false)
      }

      

    });
  }

  consultancyDropDown() {
    this.consultancyservice.getConsultancyDropDown().subscribe(
      (data) => {
        this.consultancies = data.data; // Assign the data to the consultancies array
      },
      (error) => {
        // console.log(error);
      }
    );
  }
  viewAll(Consultancy_id: any) {
    localStorage.setItem("checkPorC", "consultancy");
    this.consultancyservice.selectedConsultancy.next(Consultancy_id);  // Use next to set the value
    this.router.navigate(['vastu', Consultancy_id]);
  }

  getConsultancy(item: any) {
    localStorage.setItem("checkPorC", "consultancy");
    localStorage.setItem("consultancy_name", item.consultancy_name)
    this.poojaService.poojaOrConsultancy.next(1)
    if (item.consultancy_name == "Astrology") {
      this.poojaService.consultancyHideField.next(0)
    } else if (item.consultancy_name == "Vastu") {
      this.poojaService.consultancyHideField.next(1)
    } else {
      this.toaster.warning("Something goes wrong, Error")
    }
  }

  logout() {
    localStorage.clear(); // Replace 'UserId' with your actual key
    this.toaster.success("You've been logged out, login for stay connected with Vedang Shastra!");
    this.router.navigateByUrl('/')
    setTimeout(()=>{
      window.location.reload()
    },200)
    
    
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl(uri);
  }

  crossButton(){
    window.location.reload()
  }

  mouseEnter(){
    if (this.userinfo.value.otpno && this.userinfo.value.otpno.length == 6) {
      this.showButton = false
      document.getElementById("logConfirmOTPBtn")?.click()
    } else {
      this.showButton = true
    }
  }
  updateMetaTags(){
    this.meta.updateTag(
      { name: 'description', content: '' }, 
    );
    this.meta.updateTag(
      { name: 'keywords', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:title', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:determiner', content: '' }, 
    );
    
  }

}

















