<div class="page-container">
    <app-header></app-header>

    <div class="main-content">

        <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
            <div class="container breadcrumb-container">
                <ol class="breadcrumb">
                    <li class="breadcrum-title">Terms & Conditions</li>
                    <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img
                                src="assets/icons/home-icon.png" class="" alt=""> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Terms & Conditions</li>
                </ol>
            </div>
        </nav>

        <section class="policyModule">
            <div class="container">

                <div class="headingBlock">
                    <h3 class="section-heading text-start mb-3">Terms & Conditions</h3>

                    <span class="boldFont"><b>Terms Of Use</b></span>
                    <p class="mt-2">The Website Owner, including subsidiaries and affiliates ("Vedangshastra.com " or
                        "we" or "us" or "our" or "Vedangshastra LLP") provides the information contained on the website
                        or any of the pages comprising the website ("website") to visitors ("visitors") (cumulatively
                        referred to as "you" or "your" hereinafter) subject to the terms and conditions set out in these
                        website terms and conditions, the privacy policy and any other relevant terms and conditions,
                        policies and notices which may be applicable to a specific section or module of the website.</p>
                    <br>

                    <span class="boldFont"><b>Terms & Conditions </b></span>
                    <p class="mt-2">
                        Welcome to Vedangshastra.com. If you continue to browse and use this website you are agreeing to
                        comply with and be bound by the following terms and conditions of use, which together with our
                        privacy policy govern Vedangshastra.com relationship with you in relation to this website.
                        <br>
                        The term ‘Vedangshastra.com ’ or ‘us’ or ‘we’ refers to the owner of the website whose
                        registered office is in Maharashtra. The term ‘you’ refers to the user or viewer of our website.
                        THE RITUALS (such as pooja, prasad, abhishekam, etc.) ARE PERFORMED AT THE PLACE WHERE YOU
                        REQUEST BY REPRESENTATIVES APPOINTED BY VEDANGSHASTRA.COM BEFORE OR AFTER THE PAYMENT OF THE
                        REQUIRED FEE TO US (wherever applicable).
                        <br><br>
                        THE CHARGES MENTIONED INCLUDE THE COST OF MATERIALS REQUIRED FOR POOJA, CHARGES PAID TO OUR
                        REPRESENTATIVE FOR PERFORMING THE OFFERING AND OTHER CHARGES SUCH AS TAXES, ETC.
                        <br><br>
                        Also note, that we do NOT take any donations, chanda, chadhawa, etc. In case you find anyone
                        mis-representing us – please let us know at the earliest at info@vedangshastra.com.
                    </p>

                    <br>

                    <span class="boldFont"><b>Summary Of T&C </b></span>
                    <p>The use of this website is subject to the following terms of use:</p>
                    <ul class="mb-4 mt-2">
                        <li>The content of the pages of this website is for your general information and use only. It is
                            subject to change without notice. </li>
                        <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy,
                            timeliness, performance, completeness or suitability of the information and materials found
                            or offered on this website for any particular purpose. You acknowledge that such information
                            and materials may contain inaccuracies or errors and we expressly exclude liability for any
                            such inaccuracies or errors to the fullest extent permitted by law. </li>
                        <li>Your use of any information or materials on this website is entirely at your own risk, for
                            which we shall not be liable. It shall be your own responsibility to ensure that any
                            products, services or information available through this website meet your specific
                            requirements.</li>
                        <li>This website contains material which is owned by or licensed to us. This material includes,
                            but is not limited to, the design, layout, look, appearance and graphics. Reproduction is
                            prohibited other than in accordance with the copyright notice, which forms part of these
                            terms and conditions.</li>
                        <li>All trademarks reproduced in this website which are not the property of, or licensed to, the
                            operator are acknowledged on the website. Unauthorised use of this website may give rise to
                            a claim for damages and/or be a criminal offence </li>
                        <li>From time to time this website may also include links to other websites. These links are
                            provided for your convenience to provide further information. They do not signify that we
                            endorse the website(s). We have no responsibility for the content of the linked website(s).
                        </li>
                        <li>You may not create a link to this website from another website or document without
                            Vedangshastra’s prior written consent. </li>
                        <li>By using our website, you give us explicit permission to visit any temple on your behalf for
                            puja, prasad and related offerings. </li>
                        <li>This site uses cookies. By continuing to browse the site you are agreeing to our use of
                            cookies. Cookies are used with the primary purpose to make our website work more
                            effectively.</li>
                        <li>Your use of this website and any dispute arising out of such use of the website or its
                            contents is subject to the laws of India and to be dealt first by compulsorily arbitration
                            and if needed, in the courts of Maharashatra. </li>
                    </ul>

                    <span class="boldFont"><b>Detailed T&C </b></span> <br>
                    <p class="mt-2">
                        <b> Please read carefully the terms and conditions prior to using the website. </b><br>
                        By accessing http://Vedangshastra.com provided by Vedangshastra LLP at http://Vedangshastra.com
                        or a related URL and using this site you signify your assent and no-objection to ALL the terms
                        and conditions of use.
                    </p>


                    <span class="boldFont"><b>Terms Of Use-Disclaimer </b></span>
                    <p class="mt-2">
                        This is an User Agreement that is intended to be a legally binding contract between you and us.
                    </p>
                    <p> You agree that you are of legal age to enter into binding contracts, have read, understood and
                        are bound by the User Agreement. If you do not want to be bound by this User Agreement, you
                        should not use this Website or conduct any sale/purchase transaction. </p>
                    <p>References to "you", "User" shall mean the end user accessing the Website, its contents, purchase
                        products, place order for homas, poojas, vastu and astrology consultation offered by us and
                        using the Functionalities offered through the Website, and "we", "us" and "our" means
                        "Vedangshastra.com ". </p>
                    <p>By accessing this website, any of the pages thereof, and placing the order for any of our
                        products you are deemed to have read and agreed to the terms and conditions below. </p>

                    <span class="boldFont"><b>Website Terms Of Use </b></span>
                    <p class="mt-2">
                        <b>General</b> <br>
                        vedangshastra.com, offers religious services with its office in registered office in India. Use
                        of the Website is offered to you conditioned on acceptance of all the terms, conditions and
                        notices contained in these Terms, along with any amendments made Vedangshastra.com at its sole
                        discretion and posted on the Website.
                        <br><br>
                        <b>Functionalities Offered </b> <br>
                        This Website- http://Vedangshastra.com offers homas / yagnas, poojas, puja items, yantras,
                        astrology consultation, Vastu consultation and catering services with a payment gateway to the
                        Users.
                    </p>

                    <span class="boldFont"><b>Product Liability Disclaimer </b></span>
                    <p class="mt-2">
                        <b>All The Online Products</b> <br>
                        The product specifications (weight, size, colour etc.) mentioned with the product photos are
                        only approximate.
                        <br>
                        There may, hence, be a slight variation in the pictures and the respective products. God
                        Statues, in its absolute discretion, may deliver a similar / alternate product for reasons or
                        exigencies beyond its control.
                        <br>
                        For dimensions beyond the mentioned products, we take customized orders for which the client
                        have to pay 50% advance to process the order, the price will be mentioned once the order is
                        placed.
                    </p>

                    <span class="boldFont"><b>Astrology Consultation </b></span>
                    <p class="mt-2">
                        The horoscope and predictive advice received by members should not be used as a substitution for
                        the advice, programs, or treatment that would normally be received from a licensed professional,
                        such as a lawyer, doctor, psychiatrist, or a financial adviser.
                        <br><br>
                        Vedangshastra.com does not carry on the treatment of any specific disease, disorder or ailment,
                        nor does Vedangshastra.com promise to medically improve or alleviate any specific physical
                        condition or address any specific physical condition, or the lack thereof. By using this
                        website, you acknowledge that you are not using the services of Vedangshastra.com for the cure
                        of any specific disease, disorder, ailment, or physical condition.
                    </p>

                    <span class="boldFont"><b>Homas, Poojas & All Other Services</b></span>
                    <p class="mt-2">
                        The rate that is mentioned for all the homas and poojas are performed only for you privately or
                        anyone whom you want which must be specified while booking. If you want us to perform on group
                        basis (group sankalpam), write to us at info@vedangshastra.com.
                        <br><br>
                        VedangShastra Shall not be held responsible if the vendor/ priest does not show up or delayed to
                        your place due to any natural causes (flooding, heavy traffic etc..), accidents/health issues or
                        any other environmental/Government issues.
                        <br><br>
                        Also note that all the pandits/priests registered with Vedangshastra.com performing poojas on
                        your request are all independent contractors and therefore Vedangshastra.com shall not be
                        answerable to any dispute or problem that arises in between the User and the Pandit/Priests.
                        Kindly Note that Vedangshastra.com is just a connecting Platform for users to connect with
                        priests.
                    </p>


                    <span class="boldFont"><b>Disclaimer Of Warranty & Liability</b></span>
                    <p class="mt-2">
                        Vedangshastra.com makes no representations or warranties of any kind, express or implied, as to
                        the operation of the site or the information, content, materials, or products included on this
                        site including the fact that Vedangshastra.com is not the authorized agent or service provider
                        appointed by any temple authority for which services are being provided herein. To the full
                        extent permissible by applicable law, Vedangshastra.com disclaims all warranties, express or
                        implied, including, but not limited to, implied warranties of merchantability and fitness for a
                        particular purpose. Vedangshastra.com will not be liable for any damages of any kind arising
                        from the use of this site, including, but not limited to direct, indirect, incidental, punitive,
                        and consequential damages. It should be specifically agreed that Vedangshastra.com is not
                        responsible or liable for any threatening, defamatory, obscene, offensive or illegal content or
                        conduct of any other party or any infringement of another’s rights, including intellectual
                        property rights. It should be specifically agreed that Vedangshastra.com is not responsible for
                        any content sent using and/or included in a site/service by any third party. In no event shall
                        Vedangshastra.com be liable for any direct, indirect, punitive, incidental, special,
                        consequential damages or any damages whatsoever including, without limitation, damages for loss
                        of use, data or profits, arising out of or in any way connected with the use or performance of
                        the Vedangshastra.com services, with the delay or inability to use the Vedangshastra.com
                        site/services or related services, the provision of or failure to provide services, or for any
                        information, products, services and related graphics obtained through the Vedangshastra.com
                        site/services, or otherwise arising out of the use of the Vedangshastra.com site/services,
                        whether based on contract, tort, negligence, strict liability or otherwise, even if
                        Vedangshastra.com or any of its franchisees has been advised of the possibility of damages.
                    </p>


                    <span class="boldFont"><b>Applicable Law </b></span>
                    <p class="mt-2">
                        This agreement is governed by the laws of Republic of India. You hereby irrevocably consent to
                        the exclusive jurisdiction and venue of courts in Maharashtra, India in all disputes arising out
                        of or relating to the use of the Vedangshastra.com site/services. Use of the Vedangshastra.com
                        site/ services is unauthorized in any jurisdiction that does not give effect to all provisions
                        of these terms and conditions, including without limitation this paragraph. You agree to
                        indemnify and hold Vedangshastra.com , franchisees, subsidiaries, affiliates, officers and
                        employees, harmless from any claim, demand, or damage, including reasonable attorneys’ fees,
                        asserted by any third party due to or arising out of your use of or conduct on the
                        Vedangshastra.com site/services. Vedangshastra.com reserves the right to disclose any personal
                        information about you or your use of the Vedangshastra.com site/services, including its
                        contents, without your prior permission if Vedangshastra.com has a good faith belief that such
                        action is necessary to:
                    </p>
                    <ol type="1" class="mb-4">
                        <li>Conform to legal requirements or comply with legal process </li>
                        <li>Protect and defend the rights or property of Vedangshastra.com or its affiliated companies
                        </li>
                        <li>Enforce the terms or use</li>
                        <li>Act to protect the interests of its members or others.</li>
                    </ol>
                    <p>
                        Vedangshastra.com performance of this agreement is subject to existing laws and legal process,
                        and nothing contained in this agreement is in derogation of Vedangshastra’s right to comply with
                        governmental, court and law enforcement requests or requirements relating to your use of the
                        Vedangshastra.com site/services or information provided to or gathered by Vedangshastra.com with
                        respect to such use. If any part of this agreement is determined to be invalid or unenforceable
                        pursuant to applicable law including, but not limited to, the warranty disclaimers and liability
                        limitations set forth above, then the invalid or unenforceable provision will be deemed
                        superseded by a valid, enforceable provision that most closely matches the intent of the
                        original provision and the remainder of the agreement shall continue in effect. Unless otherwise
                        specified herein, this agreement constitutes the entire agreement between the user and
                        Vedangshastra.com with respect to the Vedangshastra.com site/services and it supersedes all
                        prior or contemporaneous communications and proposals, whether electronic, oral or written,
                        between the user and Vedangshastra.com with respect to the Vedangshastra.com site/services.
                    </p>


                    <span class="boldFont"><b>Others</b></span>
                    <p class="mt-2">
                        Most of the content at Vedangshastra.com was gathered from different books, web sites. If you
                        feel that any of the material is owned or copyrighted by you and should not be posted on the
                        internet, please write to us at info@vedangshastra.com .
                        <br><br>
                        The benefits of Homas, Poojas, Yantras, and other rituals mentioned in our website are as per
                        shastras. They are provided for users understanding. The outcome of the rituals may vary from
                        person to person and we at Vedangshastra.com do not promise any outcomes. The booking of homas
                        or poojas or any other rituals with Vedangshastra.com by the user is done on his or her sole
                        discretion.
                        <br><br>
                        Vedangshastra.com takes utmost care while shipping the items. All the items are packed with
                        standard packing materials. Shipping is done through INDIAN POST or DHL or FEDEX or BLUEDART for
                        International customers, in compliance with the laws applicable in India. Any change in
                        government policies, excise duties, taxes , etc., has to be borne by the buyer.
                        <br><br>
                        The responsibility of Vedangshastra.com ends with shipping the material from its registered
                        office in India, and provide the tracking number / ID. Any other excise duties, taxes , etc., if
                        any, in the buyer’s country has to be borne by the buyer.
                        <br><br>
                        The colour of Yantra may not be similar to the images in our website. The images are given just
                        for purpose of understanding only. Yantras are made of copper, so the colour changes are natural
                        over a period. Vedangshastra.com is not responsible for the change of colour.
                    </p>

                    <p>
                        <b> Important Note: </b> All poojas or other rituals offered through our website in sathvik way
                        as per our shastras.
                        <br><br>
                        <b> IF YOU DISAGREE WITH THIS TERMS AND CONDITIONS, DISCLAIMER, YOU ARE PROHIBITED FROM OPENING
                            OR VIEWING ANY FILES OR FOLDERS IN THIS SITE OR AVAIL OF ANY SERVICE PROVIDED IN THIS SITE.
                        </b>
                        <br><br>
                        <b> Please Note: </b> This site works best in IE8 or higher and any versions of Mozilla FireFox,
                        Chrome, Safari internet browsers.
                    </p>


                </div>

            </div>
        </section>

    </div>

    <app-footer></app-footer>

</div>