<div class="page-container">
    <app-header></app-header>

    <div class="main-content">

        <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
            <div class="container breadcrumb-container">
                <ol class="breadcrumb">
                    <li class="breadcrum-title">Privacy Policy</li>
                    <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img
                                src="assets/icons/home-icon.png" class="" alt=""> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                </ol>
            </div>
        </nav>

        <section class="policyModule">
            <div class="container">

                <div class="headingBlock">
                    <h3 class="section-heading text-start mb-3">Privacy Policy</h3>

                    <span class="boldFont"><b>Introduction </b></span>
                    <p class="mt-2">
                        This privacy policy sets out how Vedang Shastra LLP uses and protects any information that you
                        give our website Vedangshastra.com when you use this website.
                        <br><br>
                        We, at Vedangshastra.com, are committed to respecting your online privacy and recognize your
                        need for appropriate protection and management of any personally identifiable information
                        (“Personal Information”) you share with us. “Personal Information” means any information that
                        may be used to identify an individual, also including, gotra, kuldaivat a first and last name, a
                        home or other physical address, contact number and an email address or other contact
                        information.
                        <br>
                        Our privacy policy is subject to change at any time without notice. To make sure you are aware
                        of any changes, please review this policy periodically.
                    </p>

                    <span class="boldFont"><b>Your Consent </b></span>
                    <p class="mt-2">
                        Our primary goal in doing so is to provide you a safe, efficient, smooth and customised
                        experience. By using the Website and/ or by providing your information, you consent to the
                        collection and use of the information you disclose on the Website in accordance with this
                        Privacy Policy, including but not limited to Your consent for sharing your information as per
                        this privacy policy.
                        <br><br>
                        We require this information (including but not limited to) understand your needs and provide you
                        with a better service, and in particular for the following reasons:
                    </p>
                    <ol type="1" class="mb-4 mt-2">
                        <li>Internal record keeping </li>
                        <li>We may use the information to improve our products and services </li>
                        <li>We may periodically send promotional emails about new products, special offers or other
                            information which we think you may find interesting using the email address which you have
                            provided </li>
                    </ol>
                    <p>
                        From time to time, we may also use your information to contact you for market research purposes.
                        We may contact you by email, phone, fax or mail. We may use the information to customise the
                        website according to your interests
                    </p>

                    <br>

                    <span class="boldFont"><b>What Information We Collect? </b></span>
                    <p class="mt-2">
                        In general, you can browse the Website without telling us who you are or revealing any personal
                        information about yourself. Once you give us your personal information, you are not anonymous to
                        us. Where possible, we indicate which fields are required and which fields are optional. You
                        always have the option to not provide information by choosing not to use a particular service or
                        feature on the Website.
                    </p>
                    <ol type="1" class="mb-4 mt-2">
                        <li>When you order, we need to know your name, e-mail address and mailing address. This allows
                            us to process and fulfil your order and to notify you of your order status. </li>
                        <li>When you sign up for our Newsletter we need only an e-mail address – which we use to send
                            the information you requested. </li>
                        <li>When you submit a customer review, we also ask for your e-mail address, although you can
                            choose not to have your e-mail address displayed with your review.</li>
                        <li>We personalize your experience by using your purchases to shape our recommendations about
                            the temples, deities, and poojas that might be of interest to you. We also monitor customer
                            traffic patterns and site usage to help us develop the design and layout of the site.</li>
                        <li>If you choose to post messages on our message boards or other message areas or leave
                            feedback, we will collect that information you provide to us. We retain this information as
                            necessary to resolve disputes, provide customer support and troubleshoot problems as
                            permitted by law.</li>
                    </ol>

                    <span class="boldFont"><b>Information Security</b></span> <br>
                    <p class="mt-2">
                        We take appropriate security measures to protect against unauthorised access to or unauthorised
                        alteration, disclosure or destruction of data.
                        <br><br>
                        We restrict access to your personally identifying information to employees who need to know that
                        information in order to operate, develop or improve our services. In order to prevent
                        unauthorised access or disclosure we have put in place suitable physical, electronic and
                        managerial procedures to safeguard and secure the information we collect online.
                    </p>


                    <span class="boldFont"><b>Confidentiality </b></span>
                    <p class="mt-2">
                        Vedang Shastra does not rent, sell, or share personal information about you with other people
                        (save with your consent) or non-affiliated companies except to provide products or services
                        you’ve requested, when we have your permission, or under the following circumstances.
                        <br><br>
                        We provide the information to trusted partners who work on our behalf or with
                        ‘Vedangshastra.com’ under confidentiality agreements. These companies may use your personal
                        information to help Vedangshastra.com communicate with you about offers from ‘Vedangshastra.com’
                        and our marketing partners. However, these companies do not have any independent right to share
                        this information.
                        <br><br>
                        We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal
                        rights or defend against legal claims;
                        <br><br>
                        We believe it is necessary to share information in order to investigate, prevent, or take action
                        regarding illegal activities, suspected fraud, situations involving potential threats to the
                        physical safety of any person, violations of ‘Vedangshastra.com’s terms of use, or as otherwise
                        required by law. We transfer information about you if ‘Vedangshastra.com’ is acquired by or
                        merged with another company. In this event, ‘Vedangshastra.com’ will notify you before
                        information about you is transferred and becomes subject to a different privacy policy.
                    </p>

                    <span class="boldFont"><b>Images </b></span>
                    <p class="mt-2">
                        All photos, images on this site are believed to be our done poojas or public domain, they are
                        gathered from all over the internet and there is no copyright on these pictures as far as we are
                        concerned. If there is a picture on this site that has a copyright then the owner can email us
                        at info@vedangshastra.com and we will remove the picture from this site. None of the persons on
                        this site have authorised their presence here. This site is not associated with them or their
                        companies in any way. All trademarks belong to their respective owners.
                    </p>

                    <span class="boldFont"><b>Links To Other Websites </b></span>
                    <p class="mt-2">
                        Our website may contain links to other websites of interest. However, once you have used the
                        third-party links to leave our site, you should note that those links are not controlled by, or
                        affiliated to, or associated with Vedang Shastra unless expressly specified on our Website.
                        Therefore, vedangshastra.com cannot be responsible for the protection and privacy of any
                        information which you provide whilst visiting such sites and such sites are not governed by this
                        privacy statement. You should exercise caution and look at the privacy statement applicable to
                        the website in question.
                    </p>

                    <span class="boldFont"><b>Controlling Your Personal Information </b></span>
                    <p class="mt-2">
                        You may choose to restrict the collection or use of your personal information in the following
                        ways:
                        <br><br>
                        whenever you are asked to fill in a form on the website, look for the box that you can click to
                        indicate that you do not want the information to be used by anybody for direct marketing
                        purposes
                        <br><br>
                        if you have previously agreed to us using your personal information for direct marketing
                        purposes, you may change your mind at any time by writing to or emailing us at
                        info@vedangshastra.com
                        <br><br>
                        We will not sell, distribute or lease your personal information to third parties unless we have
                        your permission or are required by law to do so. We may use your personal information to send
                        you promotional information about third parties which we think you may find interesting if you
                        tell us that you wish this to happen.
                        <br><br>
                        You may request details of personal information which we hold about you under the Data
                        Protection Act 1998. A small fee will be payable. If you would like a copy of the information
                        held on you please write to info@vedangshastra.com
                        <br><br>
                        If you believe that any information we are holding on you is incorrect or incomplete, please
                        write to or email us as soon as possible, at the above address. We will promptly correct any
                        information found to be incorrect.
                    </p>

                    <p>
                        <b> IF YOU DISAGREE WITH THIS PRIVACY POLICY, YOU ARE PROHIBITED FROM OPENING OR VIEWING ANY
                            FILES OR FOLDERS IN THIS SITE OR AVAIL OF ANY SERVICE PROVIDED IN THIS SITE </b>
                        <br><br>
                    </p>


                </div>

            </div>
        </section>

    </div>

    <app-footer></app-footer>

</div>