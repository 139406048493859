import { Component, OnInit } from '@angular/core';
import { PoojaService } from './service/pooja.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'vedhangshastra';
  
  constructor(private poojaService: PoojaService){

  }

  ngOnInit(): void {
    this.poojaService.getallPooja().subscribe((res: any) => {
      console.log("get res",res);
      this.poojaService.poojaListArray.next(res.data)
    });
  }

  onActivate() {
 
    window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
     });
 }

 
}
