import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { MuhurtService } from 'src/app/service/muhurt.service';

@Component({
  selector: 'app-muhurt',
  templateUrl: './muhurt.component.html',
  styleUrls: ['./muhurt.component.scss']
})
export class MuhurtComponent implements OnInit {
  muhurtData: any = [];

  constructor(private muhurtservice: MuhurtService,private meta: Meta) { }


  ngOnInit(): void {
    this.muhurtservice.getMuhurt().subscribe((res: any) => {
      this.muhurtData = res.data
    });
    this.updateMetaTags()

  }
  updateMetaTags(){
    this.meta.updateTag(
      { name: 'description', content: '' }, 
    );
    this.meta.updateTag(
      { name: 'keywords', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:title', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:determiner', content: '' }, 
    );
    
  }

}
