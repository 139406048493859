import { CanActivateFn,Router } from '@angular/router';
import { inject } from '@angular/core';

export const yajmanFormsGuard: CanActivateFn = (route, state) => {

  let yajmanForms=localStorage.getItem("checkPorC") || ''
  
  const router=inject(Router) 
   
  if(yajmanForms.length > 0){
    return true
  }
  else{
    router.navigate([''])
    return false
  }

};
