<app-header></app-header>
<div class="page-container">
    <div class="main-content">

        <nav style="--bs-breadcrumb-divider: '>';" class="breadcrumb-section" aria-label="breadcrumb">
            <div class="container breadcrumb-container">
                <ol class="breadcrumb">
                    <li class="breadcrum-title">Yajman Address </li>
                    <li class="breadcrumb-item breadcrumb-item-1"><a href="index.html"><img
                                src="assets/icons/home-icon.png" class="" alt=""> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Yajman Address </li>
                </ol>
            </div>
        </nav>
        <section>
            <div class="container">
                <div class="right-sidebar-box">
                    <form [formGroup]="yajmanaddressForm">
                        <div class="row">

                            <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12">
                                <div class="contactForm">
                                    <h3 class="innerPageHeading text-center">Yajman Address 3</h3>
                                    <div class="row">
                                        <div class="mb-4 row-1">
                                        </div>
                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide || vastuHide">
                                                <label for="gotra-input" class="form-label">Gotra</label>
                                                <div class="custom-input">
                                                    <select class="form-select" aria-label="gotra"
                                                        formControlName="gotra">

                                                        <option selected>Select Gotra</option>
                                                        <option value="Atri">Atri</option>
                                                        <option value="Bharadvaja">Bharadvaja</option>
                                                        <option value="Bhrigu">Bhrigu</option>
                                                        <option value="Gotama">Gotama</option>
                                                        <option value="Kashyapa">Kashyapa</option>
                                                        <option value="6">Vasishtha</option>
                                                        <option value="Vasishtha">Vishvamitra</option>
                                                        <option value="Veer">Veer</option>
                                                        <option value="Nandi">Nandi</option>
                                                        <option value="Vrishabh">Vrishabh</option>
                                                        <option value="Bhrungi">Bhrungi</option>
                                                        <option value="Skanda">Skanda</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide || vastuHide">
                                                <label for="kuldevta-input" class="form-label">Kul Devta</label>
                                                <div class="custom-input">
                                                    <input type="text" class="form-control" id="kul_devta"
                                                        placeholder="Kul Devta" formControlName="kuldevta" (keypress)="validateAlphabet($event)">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form">
                                                <label for="exampleFormControlInput" class="form-label">First
                                                    Name</label><span class="required">* </span>
                                                <div class="custom-input">
                                                    <input type="text" class="form-control" id="exampleFormControlInput"
                                                        placeholder="First Name" formControlName="first_name"
                                                        (keypress)="validateAlphabet($event)">
                                                    <div class="error-message" *ngIf="
                                                        yajmanaddressForm.get('first_name')?.invalid &&
                                                        yajmanaddressForm.get('first_name')?.touched
                                                      ">
                                                        First name is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form">
                                                <label for="exampleFormControlInput1" class="form-label">Last
                                                    Name</label><span class="required">* </span>
                                                <div class="custom-input">
                                                    <input type="text" class="form-control"
                                                        id="exampleFormControlInput1" placeholder="Last Name"
                                                        formControlName="last_name"
                                                        (keypress)="validateAlphabet($event)">
                                                    <div class="error-message" *ngIf="
                                                        yajmanaddressForm.get('last_name')?.invalid &&
                                                        yajmanaddressForm.get('last_name')?.touched
                                                      ">
                                                        Last name is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--pooja date replace with consultancy date-->
                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form">
                                                <label for="exampleFormControlInput1" class="form-label">Booking
                                                    Date</label><span class="required">* </span>
                                                <div class="custom-input">
                                                    <input type="date" class="form-control upper-date"
                                                        id="exampleFormControlInput1" placeholder="Date"
                                                        formControlName="pooja_date" [min]="getTodayDate()">
                                                    <div class="error-message" *ngIf="
                                                        yajmanaddressForm.get('pooja_date')?.invalid &&
                                                        yajmanaddressForm.get('pooja_date')?.touched
                                                    ">
                                                    Booking Date is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form">
                                                <label for="exampleFormControlInput2" class="form-label">Mobile
                                                    No.</label><span class="required">* </span>
                                                <div class="custom-input">
                                                    <input type="text" class="form-control"
                                                        id="exampleFormControlInput2" placeholder="Mobile No."
                                                        (keypress)="validateMobileNumber($event)" maxlength="10"
                                                        formControlName="mobile_no">
                                                    <div class="error-message" *ngIf="
                                                        yajmanaddressForm.get('mobile_no')?.invalid &&
                                                        yajmanaddressForm.get('mobile_no')?.touched
                                                    ">
                                                        Mobile number is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form">
                                                <label for="exampleFormControlInput3"
                                                    class="form-label">Email</label><span class="required">* </span>
                                                <div class="custom-input">
                                                    <input type="email" class="form-control"
                                                        id="exampleFormControlInput3" placeholder="Email Address"
                                                        formControlName="email">
                                                    <div class="error-message" *ngIf="
                                                        yajmanaddressForm.get('email')?.invalid &&
                                                        yajmanaddressForm.get('email')?.touched
                                                      ">
                                                        Email is required.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                <label for="Country" class="form-label">Country</label><span
                                                    class="required">* </span>
                                                <div class="custom-input">
                                                    <select class="form-select" aria-label="Country"
                                                        formControlName="country">
                                                        <option value="" disabled selected>Choose Country</option>
                                                        <option [value]="cc.name" *ngFor="let cc of countrylist">
                                                            {{cc.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="error-message"
                                                    *ngIf="yajmanaddressForm.get('country')?.invalid && yajmanaddressForm.get('country')?.touched">
                                                    Country is required.
                                                </div>
                                            </div>

                                        </div>


                                        <!-- state -->
                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                <label for="state" class="form-label">State</label><span
                                                    class="required">* </span>
                                                <div class="custom-input">
                                                    <select class="form-select" aria-label="state"
                                                        formControlName="state" (ngModelChange)="getCities($event)">
                                                        <option value="" selected>Choose State</option>
                                                        <option [value]="ss.name" *ngFor="let ss of statelist">
                                                            {{ss.name}}</option>

                                                    </select>
                                                </div>
                                                <div class="error-message"
                                                    *ngIf="yajmanaddressForm.get('state')?.invalid && yajmanaddressForm.get('state')?.touched">
                                                    State is required.
                                                </div>
                                            </div>
                                        </div>

                                        <!-- city -->
                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                <label for="city" class="form-label">City</label><span
                                                    class="required">* </span>
                                                <div class="custom-input">
                                                    <select class="form-select" aria-label="state"
                                                        formControlName="city">
                                                        <option value="" disabled selected>Choose City</option>
                                                        <option [value]="ccc" *ngFor="let ccc of citylistTwo">{{ccc}}
                                                        </option>

                                                    </select>
                                                </div>
                                                <div class="error-message"
                                                    *ngIf="yajmanaddressForm.get('city')?.invalid && yajmanaddressForm.get('city')?.touched">
                                                    City is required.
                                                </div>
                                            </div>
                                        </div>
                                        <!-- </div> -->

                                        <!-- <div class="row"> -->
                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                <label for="exampleFormControlInput3" class="form-label">House/Flat
                                                    No.</label><span class="required">* </span>
                                                <div class="custom-input">
                                                    <input type="text" class="form-control"
                                                        id="exampleFormControlInput4" placeholder="House/Flat No."
                                                        formControlName="flatno">
                                                    <div class="error-message" *ngIf="
                                                         yajmanaddressForm.get('flatno')?.invalid &&
                                                         yajmanaddressForm.get('flatno')?.touched">

                                                        House/Flat No is required.

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                <label for="exampleFormControlInput3" class="form-label">Area,
                                                    Street</label><span class="required">* </span>
                                                <div class="custom-input">
                                                    <input type="text" class="form-control"
                                                        id="exampleFormControlInput5" placeholder="Area, Street"
                                                        formControlName="area">

                                                    <div class="error-message" *ngIf="
                                                         yajmanaddressForm.get('area')?.invalid &&
                                                         yajmanaddressForm.get('area')?.touched">

                                                        Area,Street is required.

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                <label for="exampleFormControlInput3"
                                                    class="form-label">Landmark</label><span class="required">* </span>
                                                <div class="custom-input">
                                                    <input type="text" class="form-control"
                                                        id="exampleFormControlInput6" placeholder="Landmark"
                                                        formControlName="landmark">
                                                    <div class="error-message" *ngIf="
                                                         yajmanaddressForm.get('landmark')?.invalid &&
                                                         yajmanaddressForm.get('landmark')?.touched">

                                                        Landmark is required.

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xxl-6 col-lg-6 col-sm-6">
                                            <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                <label for="exampleFormControlInput3"
                                                    class="form-label">Pincode</label><span class="required">* </span>
                                                <div class="custom-input">
                                                    <input type="text" class="form-control"
                                                        id="exampleFormControlInput9" placeholder="Pincode"
                                                        maxlength="6" formControlName="pincode"
                                                        (keypress)="validateMobileNumber($event)">
                                                    <div class="error-message" *ngIf="
                                                         yajmanaddressForm.get('pincode')?.invalid &&
                                                         yajmanaddressForm.get('pincode')?.touched">

                                                        Pincode is required.

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 orderSummeryModule">
                                <div class="orderSummery">
                                    <h3 class="innerPageHeading text-center">Order Summary 1</h3>
                                    <div *ngFor="let pkg of allPkg" [hidden]="!showConsultancyName">

                                        <div class="row mb-2">
                                            <div class="col-lg-12 d-flex">
                                                <div class="leftName">Pooja Name</div>
                                                <div class="colon">:</div>
                                                <div class="rightName text-start">{{pkg.poojaname}}</div>
                                            </div>
                                            
                                        </div>
                                        <div class="row mb-4">
                                            <div class="col-lg-12 d-flex">
                                                <div class="leftName">Package Name</div>
                                                <div class="colon">:</div>
                                                <div class="rightName">{{pkg.package_name}}</div>
                                            </div>
                                          
                                        </div>

                                        <hr>

                                    </div>
                                    <div class="radioInputs" *ngFor="let pkg of allPkg ">
                                        <div class="form-check mt-4 mb-2" *ngIf="!condition">
                                            <input class="form-check-input" type="radio" name="payments11"
                                                [value]="pkg.booking_amount"
                                                (change)="getradioValue(pkg.booking_amount,'advanaceradio')"
                                                [checked]="true">
                                                <div class="row">
                                                    <div class="col-lg-12 d-flex">
                                                        <div class="radioleftName"for="payAadvance">Pay Advance</div>
                                                        <div class="colon">:</div>
                                                        <div class="radiorightName">Rs. {{pkg.booking_amount}}</div>
                                                    </div>
                                                </div>
                                            
                                        </div>

                                        <div class="form-check mb-4">
                                            <input class="form-check-input" type="radio" name="payments11"
                                                [value]="pkg.package_price"
                                                (change)="getradioValue(pkg.package_price,'fullpaymnetradio')">
                                                <div class="row">
                                                    <div class="col-lg-12 d-flex">
                                                        <div class="radioleftName"for="payAadvance"> Pay Full Amount</div>
                                                        <div class="colon">:</div>
                                                        <div class="radiorightName"> Rs. {{poojaPrice}}</div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>

                                    <div class="" [hidden]="hideAdvanceRemaining">
                                        <div class="row mb-2" *ngFor="let pkg of allPkg">
                                            <div class="col-lg-12 d-flex">
                                                <div class="leftName">Advance</div>
                                                <div class="colon">:</div>
                                                <div class="rightName text-end">Rs. {{adVanceAmnt}}</div>
                                            </div>
                                        </div>
                                        <div class="row mb-4" *ngFor="let pkg of allPkg">
                                            <div class="col-lg-12 d-flex">
                                                <div class="leftName">Remaining</div>
                                                <div class="colon">:</div>
                                                <div class="rightName text-end">Rs. {{priceDifference}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="totalPriceBottom" *ngIf="!condition">
                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <span class="leftName">Total</span>
                                            </div>
                                            <div class="col-lg-6 text-end">
                                                <span class="rightName">Rs {{poojaPrice}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="btn-container mt-3" *ngIf="!condition">
                                        <button data-bs-toggle="modal" (click)="getDetailsFOrprocedd(); onSubmit();">
                                            Proceed 1111
                                        </button>
                                    </div>




                                    <!-- 25-09-23  consultancy condition -->
                                    <div *ngIf="condition">
                                        <div class="radioInputs" *ngFor="let pkg of consultancydetails ">

                                            <div class="row mb-2" [hidden]="showConsultancyName">
                                                <div class="col-lg-12 d-flex">
                                                    <div class="leftName">Consultancy Name</div>
                                                    <div class="colon">:</div>
                                                    <div class="rightName">{{pkg.consultancy_name}}</div>
                                                </div>
                                                
                                            </div>

                                            <hr [hidden]="showConsultancyName">

                                            <div [hidden]="astroHide">
                                                <div class="form-check mt-4 mb-2" *ngIf="condition">
                                                    <input class="form-check-input" type="radio" name="payments21"
                                                        [value]="pkg.booking_amount"
                                                        (change)="getradioValueConsultancy(pkg.booking_amount,'advanaceradio')"
                                                        checked="true">
                                                        <div class="row">
                                                            <div class="col-lg-12 d-flex">
                                                                <div class="radioleftName">
                                                                    Pay Advance 
                                                                </div>
                                                                <div class="colon">:</div>
                                                                <div class="radiorightName">Rs. {{pkg.booking_amount}}</div>
                                                            </div>
                                                        </div>
                                                       
                                                   
                                                </div>
                                            </div>
                                            <div class="form-check mb-4">
                                                <input class="form-check-input" type="radio" name="payments21"
                                                    [value]="pkg.package_price"
                                                    (change)="getradioValueConsultancy(pkg.package_price,'fullpaymnetradio')"
                                                    [checked]="astroHide">
                                                    <div class="row">
                                                        <div class="d-flex col-lg-12">
                                                            <div class="radioleftName" for="payFullPayment">
                                                                Pay Full Amount
                                                            </div>
                                                            <div class="colon">:</div>
                                                            <div class="radiorightName"> Rs. {{package_price}}</div>
                                                        </div>
                                                    </div>
                                                   
                                                
                                            </div>
                                        </div>

                                        <div [hidden]="hideConsultancyAdvanceRemaining">
                                            <div class="row mb-2" *ngFor="let pkg of consultancydetails"
                                                [hidden]="astroHide">
                                                <div class="col-lg-12 d-flex">
                                                    <div class="leftName">Advance</div>
                                                    <div class="colon">:</div>
                                                    <div class="rightName">Rs. {{adVanceAmnt}}</div>
                                                </div>
                                                
                                            </div>
                                            <div class="row mb-4" *ngFor="let pkg of consultancydetails"
                                                [hidden]="astroHide">
                                                <div class="col-lg-12 d-flex">
                                                    <div class="leftName">Remaining</div>
                                                    <div class="colon">:</div>
                                                    <div class="rightName">Rs. {{priceDifference}}</div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div class="totalPriceBottom" *ngIf="condition">
                                            <div class="row mt-3">
                                                <div class="col-lg-6">
                                                    <span class="leftName">Total</span>
                                                </div>
                                                <div class="col-lg-6 text-end">
                                                    <span class="rightName">Rs {{package_price}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn-container mt-3" *ngIf="condition">
                                            <button data-bs-toggle="modal"
                                                (click)="getDetailsFOrprocedd(); onSubmitConsultancy();">

                                                Proceed Consultancy
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <!-- Model -->

                                <div class="modal fade proceedModal" id="proceedModal" data-bs-backdrop="static"
                                    data-bs-keyboard="false" tabindex="-1" aria-labelledby="proceedModalLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header justify-content-center">
                                                <h3 class="modal-title innerPageHeading mt-3">Payment and Order Summary
                                                </h3>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row">
                                                    <div class="col-xl-6 col-md-12">
                                                        <div class="deliveryAddress">
                                                            <h4 class="innerTitle">Delivery Address</h4>
                                                            <div class="checkout-detail">
                                                                <div class="row g-4">
                                                                    <div class="col-lg-12 col-md-6">
                                                                        <div class="delivery-address-box">
                                                                            <div>

                                                                                <ul class="delivery-address-detail">
                                                                                    <li
                                                                                        class="d-flex justify-content-between">
                                                                                        <h4 class="fw-500 userName">
                                                                                            {{concatFullname}}</h4>
                                                                                        <span class="chgeAddress ms-5"
                                                                                            (click)="viewAll()"
                                                                                            data-bs-target="#exampleModalToggle2"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-dismiss="modal">Change
                                                                                            1
                                                                                            <img src="assets/icons/edit.svg"
                                                                                                alt=""
                                                                                                class="editIcon"></span>
                                                                                    </li>

                                                                                    <li [hidden]="astroHide">
                                                                                        <p class="text-content"><span
                                                                                                class="text-title">Address
                                                                                                : </span>{{concatAddr}}
                                                                                        </p>
                                                                                    </li>

                                                                                    <li>
                                                                                        <h6 class="text-content mb-0">
                                                                                            <span
                                                                                                class="text-title">Phone
                                                                                                No.
                                                                                                :</span> {{mobileNo}}
                                                                                        </h6>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="paymentOption mt-4">
                                                            <h4 class="innerTitle">Payment Option</h4>
                                                            <div class="checkout-detail">
                                                                <div class="accordion accordion-flush custom-accordion"
                                                                    id="accordionFlushExample">
                                                                    <div class="accordion-item mb-3">
                                                                        <div class="accordion-header"
                                                                            id="flush-headingFour">
                                                                            <div class="accordion-button collapsed"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#flush-collapseFour">
                                                                                <div
                                                                                    class="custom-form-check form-check mb-0">
                                                                                    <label class="form-check-label"
                                                                                        for="cash"><input
                                                                                            class="form-check-input mt-0"
                                                                                            type="radio"
                                                                                            name="flexRadioDefault"
                                                                                            id="cash" checked> UPI or
                                                                                        Online Payment</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div id="flush-collapseFour"
                                                                            class="accordion-collapse collapse show"
                                                                            data-bs-parent="#accordionFlushExample">
                                                                            <div class="accordion-body">
                                                                                <p class="cod-review">Pay digitally with
                                                                                    SMS Pay
                                                                                    Link. Cash may not be accepted in
                                                                                    COVID restricted
                                                                                    areas. <a
                                                                                        href="javascript:void(0)">Know
                                                                                        more.</a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="accordion-item mb-3">
                                                                        <div class="accordion-header"
                                                                            id="flush-headingOne">
                                                                            <div class="accordion-button collapsed"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#flush-collapseOne">
                                                                                <div
                                                                                    class="custom-form-check form-check mb-0">
                                                                                    <label class="form-check-label"
                                                                                        for="credit"><input
                                                                                            class="form-check-input mt-0"
                                                                                            type="radio"
                                                                                            name="flexRadioDefault"
                                                                                            id="credit">
                                                                                        Credit or Debit Card</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div id="flush-collapseOne"
                                                                            class="accordion-collapse collapse"
                                                                            data-bs-parent="#accordionFlushExample">
                                                                            <div class="accordion-body">
                                                                                <div class="row g-2">
                                                                                    <div class="col-12">
                                                                                        <div class="payment-method">
                                                                                            <div
                                                                                                class="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    id="credit2"
                                                                                                    placeholder="Enter Credit & Debit Card Number">
                                                                                                <label
                                                                                                    for="credit2">Enter
                                                                                                    Credit & Debit
                                                                                                    Card Number</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-xxl-4">
                                                                                        <div
                                                                                            class="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                id="expiry"
                                                                                                placeholder="Enter Expiry Date">
                                                                                            <label for="expiry">Expiry
                                                                                                Date</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-xxl-4">
                                                                                        <div
                                                                                            class="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                id="cvv"
                                                                                                placeholder="Enter CVV Number">
                                                                                            <label for="cvv">CVV
                                                                                                Number</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-xxl-4">
                                                                                        <div
                                                                                            class="form-floating mb-lg-3 mb-2 theme-form-floating">
                                                                                            <input type="password"
                                                                                                class="form-control"
                                                                                                id="password"
                                                                                                placeholder="Enter Password">
                                                                                            <label
                                                                                                for="password">Password</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="button-group mt-0">
                                                                                        <ul
                                                                                            class="d-flex justify-content-end">
                                                                                            <li>
                                                                                                <button
                                                                                                    class="btn-gray">Cancel</button>
                                                                                            </li>

                                                                                            <li>
                                                                                                <button>Use This
                                                                                                    Card</button>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="accordion-item">
                                                                        <div class="accordion-header"
                                                                            id="flush-headingTwo">
                                                                            <div class="accordion-button collapsed"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#flush-collapseTwo">
                                                                                <div
                                                                                    class="custom-form-check form-check mb-0">
                                                                                    <label class="form-check-label"
                                                                                        for="banking"><input
                                                                                            class="form-check-input mt-0"
                                                                                            type="radio"
                                                                                            name="flexRadioDefault"
                                                                                            id="banking">Net
                                                                                        Banking</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div id="flush-collapseTwo"
                                                                            class="accordion-collapse collapse"
                                                                            data-bs-parent="#accordionFlushExample">
                                                                            <div class="accordion-body">
                                                                                <h5 class="text-uppercase mb-4">Select
                                                                                    Your Bank
                                                                                </h5>
                                                                                <div class="row g-2">
                                                                                    <div class="col-md-6">
                                                                                        <div
                                                                                            class="custom-form-check form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                name="flexRadioDefault"
                                                                                                id="bank1">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="bank1">Industrial &
                                                                                                Commercial
                                                                                                Bank</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-6">
                                                                                        <div
                                                                                            class="custom-form-check form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                name="flexRadioDefault"
                                                                                                id="bank2">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="bank2">Agricultural
                                                                                                Bank</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-6">
                                                                                        <div
                                                                                            class="custom-form-check form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                name="flexRadioDefault"
                                                                                                id="bank3">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="bank3">Bank
                                                                                                of America</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-6">
                                                                                        <div
                                                                                            class="custom-form-check form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                name="flexRadioDefault"
                                                                                                id="bank4">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="bank4">Construction
                                                                                                Bank Corp.</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-6">
                                                                                        <div
                                                                                            class="custom-form-check form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                name="flexRadioDefault"
                                                                                                id="bank5">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="bank5">HSBC
                                                                                                Holdings</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-md-6">
                                                                                        <div
                                                                                            class="custom-form-check form-check">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                name="flexRadioDefault"
                                                                                                id="bank6">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="bank6">JPMorgan
                                                                                                Chase & Co.</label>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-12">
                                                                                        <div class="select-option">
                                                                                            <div
                                                                                                class="form-floating theme-form-floating">
                                                                                                <select
                                                                                                    class="form-select theme-form-select"
                                                                                                    aria-label="Default select example">
                                                                                                    <option
                                                                                                        value="hsbc">
                                                                                                        HSBC Holdings
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="loyds">
                                                                                                        Lloyds Banking
                                                                                                        Group</option>
                                                                                                    <option
                                                                                                        value="natwest">
                                                                                                        Nat West Group
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="Barclays">
                                                                                                        Barclays
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="other">
                                                                                                        Others Bank
                                                                                                    </option>
                                                                                                </select>
                                                                                                <label>Select Other
                                                                                                    Bank</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div class="col-xl-6 col-md-12">
                                                        <div class="orderSummery">
                                                            <h3 class="innerTitle text-center">Order Summary 2</h3>
                                                            <div class="row mb-2" [hidden]="!showConsultancyName">
                                                                <div class="col-lg-12 d-flex"  *ngFor="let pkg of allPkg">
                                                                    <div class="leftName">Pooja Name</div>
                                                                    <div class="colon">:</div>
                                                                    <div class="rightName">{{pkg?.poojaname}}</div>
                                                                </div>
                                                               
                                                            </div>
                                                            <div class="row mb-4" [hidden]="!showConsultancyName">
                                                                <div class="col-lg-12 d-flex" *ngFor="let pkg of allPkg">
                                                                    <div class="leftName">Package Name</div>
                                                                    <div class="colon">:</div>
                                                                    <div class="rightName">{{pkg?.package_name}}</div>
                                                                </div>
                                                                
                                                            </div>
                                                            <hr [hidden]="!showConsultancyName">

                                                            <!--consultancy payment order summary-->
                                                            <div [hidden]="showConsultancyName">
                                                                <div class="row mb-2">
                                                                    <div class="col-lg-12 d-flex"  *ngFor="let pkg of consultancydetails">
                                                                        <div class="leftName">Consultancy Name</div>
                                                                        <div class="colon">:</div>
                                                                        <div class="rightName">{{pkg?.consultancy_name}}</div>
                                                                    </div>
                                                                </div>

                                                                <!--Astro-->
                                                                <div class=" mt-4 mb-2"
                                                                    *ngFor="let pkg of consultancydetails">
                                                                    <div class="form-check mt-4 mb-2"
                                                                        [hidden]="astroHide">
                                                                        <input class="form-check-input" type="radio"
                                                                            name="payments3110" id="pay_advance1"
                                                                            [value]="pkg.booking_amount"
                                                                            (change)="getradioValue(pkg.booking_amount,'advanaceradio')"
                                                                            [checked]="!checkedVastu" disabled>
                                                                            <div class="row">
                                                                                <div class="col-lg-12 d-flex">
                                                                                    <div class="radioleftName">Pay Advance C </div>
                                                                                    <div class="colon">:</div>
                                                                                    <div class="radiorightName">Rs. {{pkg.booking_amount}}</div>
                                                                                </div>
                                                                            </div>
                                                                    </div>

                                                                    <div class="form-check mt-4 mb-2">
                                                                        <input class="form-check-input" type="radio"
                                                                            name="payments3120" id="pay_advance2"
                                                                            [value]="pkg.package_price"
                                                                            (change)="getradioValue(pkg.package_price,'advanaceradio')"
                                                                            [checked]="checkedVastu" disabled>
                                                                            <div class="row">
                                                                                <div class="col-lg-12 d-flex">
                                                                                    <div class="radioleftName"> Pay Full Amount C </div>
                                                                                    <div class="colon">:</div>
                                                                                    <div class="radiorightName">Rs.
                                                                                        {{pkg.package_price}}</div>
                                                                                </div>
                                                                            </div>
                                                                        
                                                                    </div>
                                                                    <div [hidden]="checkedVastu">
                                                                        <hr [hidden]="astroHide">
                                                                        <div class="row" [hidden]="astroHide">
                                                                            <div class="col-lg-12 d-flex">
                                                                                <div class="leftName">Advance C</div>
                                                                                <div class="colon">:</div>
                                                                                <div class="rightName">Rs. {{pkg.booking_amount}}</div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <div class="row" [hidden]="astroHide">
                                                                            <div class="col-lg-12 d-flex">
                                                                                <div class="leftName">Remaining C</div>
                                                                                <div class="colon">:</div>
                                                                                <div class="rightName">Rs. {{pkg.package_price -
                                                                                        pkg.booking_amount}}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-lg-6">
                                                                            <span class="leftName">Total C</span>
                                                                        </div>
                                                                        <div class="col-lg-6 text-end">
                                                                            <span class="rightName">Rs
                                                                                {{pkg.package_price}}</span>
                                                                        </div>
                                                                    </div>

                                                                </div>




                                                            </div>



                                                            <div class="radioInputs" *ngFor="let pkg of allPkg ">
                                                                <div class="form-check mt-4 mb-2">
                                                                    <input class="form-check-input" type="radio"
                                                                        name="payments31" id="pay_advance"
                                                                        [value]="pkg.booking_amount"
                                                                        (change)="getradioValue(pkg.booking_amount,'advanaceradio')"
                                                                        [checked]="!hideAdvanceRemaining" disabled>
                                                                        <div class="row">
                                                                            <div class="col-lg-12 d-flex">
                                                                                <div class="radioleftName"> Pay Advance </div>
                                                                                <div class="colon">:</div>
                                                                                <div class="radiorightName">Rs.
                                                                                    {{pkg.booking_amount}}</div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                </div>

                                                                <div class="form-check mb-4">
                                                                    <input class="form-check-input" type="radio"
                                                                        name="payments31" [value]="pkg.package_price"
                                                                        (change)="getradioValue(pkg.package_price,'fullpaymnetradio')"
                                                                        [checked]="hideAdvanceRemaining" disabled>
                                                                        <div class="row">
                                                                            <div class="col-lg-12 d-flex">
                                                                                <div class="radioleftName"> Pay Full Amount</div>
                                                                                <div class="colon">:</div>
                                                                                <div class="radiorightName"> Rs.
                                                                                    {{poojaPrice}}</div>
                                                                            </div>
                                                                        </div>
                                                                   
                                                                </div>
                                                            </div>

                                                            <div class="row mb-2" *ngFor="let pkg of allPkg">
                                                                <div class="col-lg-12 d-flex">
                                                                    <div class="leftName">Advance</div>
                                                                    <div class="colon">:</div>
                                                                    <div class="rightName">Rs. {{adVanceAmnt}}</div>
                                                                </div>
                                                               
                                                            </div>
                                                            <div class="row mb-4" *ngFor="let pkg of allPkg">
                                                                <div class="col-lg-12 d-flex">
                                                                    <div class="leftName">Remaining</div>
                                                                    <div class="colon">:</div>
                                                                    <div class="rightName">Rs. {{priceDifference}}</div>
                                                                </div>
                                                               
                                                            </div>

                                                            <div class="totalPriceBottom" *ngIf="!condition">
                                                                <div class="row mt-3">
                                                                    <div class="col-lg-6">
                                                                        <span class="leftName">Total</span>
                                                                    </div>
                                                                    <div class="col-lg-6 text-end">
                                                                        <span class="rightName">Rs {{poojaPrice}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="btn-container mt-3">
                                                                <button type="button" style="width: 100%"
                                                                    (click)="paymentOrderSummary()"
                                                                    data-bs-dismiss="modal" aria-label="Close">Proceed
                                                                    sadasd PAY ORDER SUMMARY</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- <button class="btn btn-animation btn-md fw-bold ms-auto">Send Message</button> -->
                    </form>
                </div>

            </div>
        </section>

        <!-- edit model  -->
        <section>
            <form [formGroup]="yajmanaddressForm" (ngSubmit)="updateAddress()">

                <div class="modal fade" id="exampleModalToggle2" aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalToggleLabel2"></h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    (click)="reopenPaymentModal()"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12">
                                        <div class="contactForm">
                                            <h3 class="innerPageHeading text-center">Yajman Address Update</h3>
                                            <div class="row">
                                                <div class="row mb-4 row-1">
                                                </div>
                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form"
                                                        [hidden]="astroHide || vastuHide">
                                                        <label for="gotra-input" class="form-label">Gotra</label>
                                                        <div class="custom-input">
                                                            <select class="form-select" aria-label="gotra"
                                                                formControlName="gotra">

                                                                <option selected>Select Gotra</option>
                                                                <option value="Atri">Atri</option>
                                                                <option value="Bharadvaja">Bharadvaja</option>
                                                                <option value="Bhrigu">Bhrigu</option>
                                                                <option value="Gotama">Gotama</option>
                                                                <option value="Kashyapa">Kashyapa</option>
                                                                <option value="6">Vasishtha</option>
                                                                <option value="Vasishtha">Vishvamitra</option>
                                                                <option value="Veer">Veer</option>
                                                                <option value="Nandi">Nandi</option>
                                                                <option value="Vrishabh">Vrishabh</option>
                                                                <option value="Bhrungi">Bhrungi</option>
                                                                <option value="Skanda">Skanda</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form"
                                                        [hidden]="astroHide || vastuHide">
                                                        <label for="kuldevta-input" class="form-label">Kul Devta</label>
                                                        <div class="custom-input">
                                                            <input type="text" class="form-control" id="kul_devta"
                                                                placeholder="Kul Devta" formControlName="kuldevta"  (keypress)="validateAlphabet($event)"
                                                                [ngClass]="{ 'is-invalid': submitted && f['kuldevta'].errors}">
                                                            <div *ngIf="submitted && f['kuldevta'].errors"
                                                                class="invalid-feedback">
                                                                <div *ngIf="f['kuldevta'].errors['required']">This field
                                                                    is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form">
                                                        <label for="exampleFormControlInput" class="form-label">First
                                                            Name</label>
                                                        <div class="custom-input">

                                                            <input type="text" class="form-control"
                                                                id="exampleFormControlInput" placeholder="First Name"
                                                                formControlName="first_name"  (keypress)="validateAlphabet($event)">

                                                            <div class="error-message" *ngIf="
                                                                yajmanaddressForm.get('first_name')?.invalid &&
                                                                yajmanaddressForm.get('first_name')?.touched
                                                            ">
                                                                First Name is required.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form">
                                                        <label for="exampleFormControlInput1" class="form-label">Last
                                                            Name</label>
                                                        <div class="custom-input">
                                                            <input type="text" class="form-control"
                                                                id="exampleFormControlInput1" placeholder="Last Name"
                                                                formControlName="last_name"  (keypress)="validateAlphabet($event)">

                                                            <div class="error-message" *ngIf="
                                                                yajmanaddressForm.get('last_name')?.invalid &&
                                                                yajmanaddressForm.get('last_name')?.touched
                                                            ">
                                                                Last Name is required.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label">Booking
                                                            Date Update</label>
                                                        <div class="custom-input">
                                                            <input type="date" class="form-control upper-date"
                                                                id="exampleFormControlInput1" placeholder="Date"
                                                                formControlName="pooja_date" [min]="getTodayDate()">

                                                            <div class="error-message" *ngIf="
                                                                yajmanaddressForm.get('pooja_date')?.invalid &&
                                                                yajmanaddressForm.get('pooja_date')?.touched
                                                            ">
                                                                Booking Date is required.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form">
                                                        <label for="exampleFormControlInput2" class="form-label">Mobile
                                                            No.</label>
                                                        <div class="custom-input">
                                                            <input type="text" class="form-control"
                                                                (keypress)="validateMobileNumber($event)" maxlength="10"
                                                                id="exampleFormControlInput2" placeholder="Mobile No."
                                                                formControlName="mobile_no">
                                                            <div class="error-message" *ngIf="
                                                                yajmanaddressForm.get('mobile_no')?.invalid &&
                                                                yajmanaddressForm.get('mobile_no')?.touched
                                                            ">
                                                                Mobile number is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form">
                                                        <label for="exampleFormControlInput3"
                                                            class="form-label">Email</label>
                                                        <div class="custom-input">
                                                            <input type="email" class="form-control"
                                                                id="exampleFormControlInput" placeholder="Email Address"
                                                                formControlName="email">

                                                            <div class="error-message" *ngIf="
                                                                yajmanaddressForm.get('email')?.invalid &&
                                                                yajmanaddressForm.get('email')?.touched
                                                            ">
                                                                Email is required.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                        <label for="Country" class="form-label">Country</label>
                                                        <div class="custom-input">
                                                            <input class="form-select" aria-label="Country"
                                                                formControlName="country" [readonly]="true">
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- state -->
                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                        <label for="state" class="form-label">State</label>
                                                        <div class="custom-input">
                                                            <input class="form-select" aria-label="state"
                                                                formControlName="state" [readonly]="true">
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- city -->
                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                        <label for="city" class="form-label">City</label>
                                                        <div class="custom-input">
                                                            <select class="form-select" aria-label="state"
                                                                formControlName="city">
                                                                <option value="" disabled selected>Choose City</option>
                                                                <option [value]="ccc" *ngFor="let ccc of citylistTwo">
                                                                    {{ccc}}</option>

                                                            </select>
                                                            <div class="error-message" *ngIf="
                                                            yajmanaddressForm.get('city')?.invalid &&
                                                            yajmanaddressForm.get('city')?.touched
                                                        ">
                                                                City is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                        <label for="exampleFormControlInput3"
                                                            class="form-label">House/Flat No.</label>
                                                        <div class="custom-input">
                                                            <input type="text" class="form-control"
                                                                id="exampleFormControlInput4"
                                                                placeholder="House/Flat No." formControlName="flatno">

                                                            <div class="error-message" *ngIf="
                                                            yajmanaddressForm.get('flatno')?.invalid &&
                                                            yajmanaddressForm.get('flatno')?.touched
                                                        ">
                                                                Flat No is required.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                        <label for="exampleFormControlInput3" class="form-label">Area,
                                                            Street</label>
                                                        <div class="custom-input">
                                                            <input type="text" class="form-control"
                                                                id="exampleFormControlInput5" placeholder="Area, Street"
                                                                formControlName="area">

                                                            <div class="error-message" *ngIf="
                                                                yajmanaddressForm.get('area')?.invalid &&
                                                                yajmanaddressForm.get('area')?.touched
                                                            ">
                                                                Area is required.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                        <label for="exampleFormControlInput3"
                                                            class="form-label">Landmark</label>
                                                        <div class="custom-input">
                                                            <input type="text" class="form-control"
                                                                id="exampleFormControlInput6" placeholder="Landmark"
                                                                formControlName="landmark">

                                                            <div class="error-message" *ngIf="
                                                                yajmanaddressForm.get('landmark')?.invalid &&
                                                                yajmanaddressForm.get('landmark')?.touched
                                                            ">
                                                                Landmark is required.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xxl-6 col-lg-6 col-sm-6">
                                                    <div class="mb-md-4 mb-3 custom-form" [hidden]="astroHide">
                                                        <label for="exampleFormControlInput3"
                                                            class="form-label">Pincode</label>
                                                        <div class="custom-input">
                                                            <input type="text" class="form-control"
                                                                id="exampleFormControlInput9" placeholder="Pincode"
                                                                (keypress)="validateMobileNumber($event)" maxlength="6"
                                                                formControlName="pincode">
                                                            <div class="error-message" *ngIf="
                                                                yajmanaddressForm.get('pincode')?.invalid &&
                                                                yajmanaddressForm.get('pincode')?.touched
                                                            ">
                                                                Pincode is required.
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 orderSummeryModule">
                                        <div class="orderSummery">
                                            <h3 class="innerTitle text-center">Order Summary 4</h3>

                                            <div [hidden]="showConsultancyName">
                                                <div class="row mb-2">
                                                    <div class="col-lg-12 d-flex" *ngFor="let pkg of consultancydetails">

                                                        <div class="leftName">Consultancy Name</div>
                                                        <div class="colon">:</div>
                                                        <div class="rightName">{{pkg?.consultancy_name}}</div>
                                                    </div>
                                                </div>

                                                <!--Astro-->
                                                <div class="mt-4 mb-2"
                                                    *ngFor="let pkg of consultancydetails">
                                                    <div class=" form-check mt-4 mb-2" [hidden]="astroHide">
                                                        <input class="form-check-input" type="radio" name="nameone"
                                                            id="pay_advance1" [value]="pkg.booking_amount"
                                                            (change)="getradioValue(pkg.booking_amount,'advanaceradio')"
                                                            [checked]="!checkedVastu">
                                                        <div class="row">
                                                            <div class="col-lg-12 d-flex">
                                                                <div class="radioleftName"> Pay Advance C</div>
                                                                <div class="colon">:</div>
                                                                <div class="radiorightName">Rs. {{pkg.booking_amount}}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-check mt-4 mb-2">
                                                        <input class="form-check-input" type="radio" name="nameone"
                                                            id="pay_advance2" [value]="pkg.package_price"
                                                            (change)="getradioValue(pkg.package_price,'fullpaymnetradio'); "
                                                            [checked]="checkedVastu">
                                                            <div class="row">
                                                                <div class="col-lg-12 d-flex">
                                                                    <div class="radioleftName"> Pay Full Amount C</div>
                                                                    <div class="colon">:</div>
                                                                    <div class="radiorightName">Rs. {{pkg.package_price}}</div>
                                                                </div>
                                                            </div>
                                                       
                                                    </div>
                                                    <div [hidden]="checkedVastu">
                                                        <hr [hidden]="astroHide">
                                                        <div class="row" [hidden]="astroHide">
                                                            <div class="col-lg-12 d-flex">
                                                                <div class="leftName">Advance C</div>
                                                                <div class="colon"></div>
                                                                <div class="rightName">Rs. {{pkg.booking_amount}}</div>
                                                            </div>
                                                           
                                                        </div>
                                                        <div class="row" [hidden]="astroHide">
                                                            <div class="col-lg-12 d-flex">
                                                                <div class="leftName">Remaining C</div>
                                                                <div class="colon">:</div>
                                                                <div class="rightName">Rs. {{pkg.package_price -
                                                                    pkg.booking_amount}}</div>
                                                            </div>
                                                          
                                                        </div>
                                                    </div>

                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <span class="leftName">Total C</span>
                                                        </div>
                                                        <div class="col-lg-6 text-end">
                                                            <span class="rightName">Rs {{pkg.package_price}}</span>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="row mb-2" [hidden]="!showConsultancyName">
                                                <div class="col-lg-12 d-flex"  *ngFor="let pkg of allPkg">
                                                    <div class="leftName">Pooja Name</div>
                                                    <div class="colon">:</div>
                                                    <div class="rightName">{{pkg.poojaname}}</div>
                                                </div>
                                               
                                            </div>
                                            <div class="row mb-4" [hidden]="!showConsultancyName">
                                                <div class="col-lg-12 d-flex" *ngFor="let pkg of allPkg">
                                                    <div class="leftName">Package Name</div>
                                                    <div class="colon">:</div>
                                                    <div class="rightName">{{pkg.package_name}}</div>
                                                </div>
                                               
                                            </div>
                                            <div class="radioInputs" *ngFor="let pkg of allPkg "
                                                [hidden]="!showConsultancyName">
                                                <div class="form-check mt-4 mb-2">
                                                    <input class="form-check-input" type="radio" name="payments41"
                                                        id="pay_advance" checked [value]="pkg.booking_amount"
                                                        (change)="getradioValue(pkg.booking_amount,'advanaceradio')">
                                                        <div class="row">
                                                            <div class="col-lg-12 d-flex">
                                                                <div class="radioleftName">Pay Advance</div>
                                                                <div class="colon">:</div>
                                                                <div class="radiorightName">Rs. {{pkg.booking_amount}}</div>
                                                            </div>
                                                        </div>
                                                   
                                                </div>
                                                <div class="form-check mb-4">
                                                    <input class="form-check-input" type="radio" name="payments41"
                                                        [value]="pkg.package_price"
                                                        (change)="getradioValue(pkg.package_price,'fullpaymnetradio')">
                                                        <div class="row">
                                                            <div class="col-lg-12 d-flex">
                                                                <div class="radioleftName">Pay Full Amount</div>
                                                                <div class="colon">:</div>
                                                                <div class="radiorightName"> Rs. {{poojaPrice}}</div>
                                                            </div>
                                                        </div>
                                                    
                                                </div>
                                            </div>

                                            <div class="row mb-2" *ngFor="let pkg of allPkg">
                                                <div class="col-lg-12 d-flex">
                                                    <div class="leftName">Advance</div>
                                                    <div class="colon">:</div>
                                                    <div class="rightName">Rs. {{adVanceAmnt}}</div>
                                                </div>
                                               
                                            </div>
                                            <div class="row mb-4" *ngFor="let pkg of allPkg">
                                                <div class="col-lg-12 d-flex">
                                                    <div class="leftName">Remaining</div>
                                                    <div class="colo0n">:</div>
                                                    <div class="rightName">Rs. {{priceDifference}}</div>
                                                </div>
                                               
                                            </div>

                                            <div class="totalPriceBottom" *ngIf="!condition">
                                                <div class="row mt-3">
                                                    <div class="col-lg-6">
                                                        <span class="leftName">Total</span>
                                                    </div>
                                                    <div class="col-lg-6 text-end">
                                                        <span class="rightName">Rs {{poojaPrice}}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="btn-container mt-3">
                                                <button type="button" (click)="updateAddress()" data-bs-dismiss="modal"
                                                    aria-label="Close">Update Address</button>
                                            </div>
                                        </div>
                                       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </section>

    </div>

    <app-footer></app-footer>
</div>