<header class="fixed-header">
  <div class="main-div">
    <div class="container-fluid">
      <div class="call-us-container">
        <div class="d-flex align-items-center justify-content-end">

          <div class="d-flex call-section">
            <img src="assets/icons/Call.svg" class="call-icon" alt="call icon">
            <h6><a href="tel:+91 9876543210">+91 8655346055</a></h6>
          </div>

        </div>
      </div>
    </div>
  </div>

  <nav class="navbar navbar-expand-lg navbar-light main-header justify-content-center">

    <div class="container-fluid main-header-container ">
      <a class="navbar-brand" href="#"><img src="assets/images/vedhang-shastra-logo.png" alt="vedhang shastra logo"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" routerLink="/list-of-pooja" (click)="listOfPooja()"
              id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Pooja
              <img src="../../../assets/icons/down-arrows.png" alt="down-arrow" class="down-arrow">
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <span *ngFor="let options of data">
                <li class="dropdown-item" (click)="getSelectedPooja(options.categoryname,options.id)">
                  {{options.categoryname}}</li>

              </span>
            </ul>

          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/muhurt">Muhurt</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown"
              aria-expanded="false" (click)="setLocal()">
              Consultancy
              <img src="../../../assets/icons/down-arrows.png" alt="down-arrow" class="down-arrow">
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li *ngFor="let item of consultancies">
                <a class="dropdown-item" (click)="viewAll(item.id); getConsultancy(item)">{{ item.consultancy_name
                  }}</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/contact-us">Contact us</a>
          </li>
          <li class="nav-item dropdown icon">
            <a class="nav-link dropdown-toggle" *ngIf="!showLogout" href="#" id="navbarDropdownMenuLink" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img src="assets/icons/Account.png" class="header-icon" alt="account icon" data-bs-toggle="modal"
                data-bs-target="#loginModal">
            </a>
            <a class="nav-link dropdown-toggle" *ngIf="showLogout" id="navbarDropdownMenuLink" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img src="assets/icons/Account.png" class="header-icon" alt="account icon">
            </a>

            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" *ngIf="showLogout">
              <li><a class="dropdown-item" routerLink="/my-booking">Pooja bookings</a></li>
              <li><a class="dropdown-item" routerLink="/astrology">Consultancy bookings</a></li>
              <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<!-- Login Modal -->

<!-- hhhhhhhhhhhhhhhhhhhhhhhh -->
<div class="modal fade" id="loginModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="crossButton()"></button>
      </div>
      <div class="modal-body">
        <h5 class="modal-title text-center section-heading" id="loginModalLabel">Log In</h5>
        <form [formGroup]="userinfo">
          <div class="mb-3">
            <label for="" class="form-label">Email</label><span class="required">* </span>
            <input type="text" class="form-control" id="email" type="email" name="email" formControlName="email"
              (keyup)="getPhoneNo($event)" (keyup.enter)="newFunction()" placeholder="Enter Your Email">
            <div class="error-message" *ngIf="
                      userinfo.get('email')?.invalid &&
                      userinfo.get('email')?.touched
                    ">
              Email is required.
            </div>
          </div>
          <div *ngIf="!pViaOtp">
            <label for="" class="form-label">OTP</label>
            <input type="text" class="form-control" id="otp" maxlength="6" (keyup)="disableOTP($event)" (mouseenter)="mouseEnter()" (keyup.enter)="mouseEnter()"
              placeholder="Enter OTP" name="otp_number" formControlName="otpno" (input)="onInput($event)">
          </div>
          <div class="text-end mb-3">
            <span class="text-end" *ngIf="timer && timerAndResend">00:{{timeValue}}</span>
            <span class="text-end cpointer" *ngIf="!timer && timerAndResend" (click)="resendOTP()"><a
                style="text-decoration: underline; color: blue; cursor: pointer;">Resend OTP</a></span>
          </div>
          <div class="mb-3 text-center" *ngIf="pViaOtp">
            <button type="button" class="btn-proceed " id="btn-pro" *ngIf="checkoutFlag" (click)="newFunction()">Proceed
              via OTP</button>
          </div>

          <div class="mb-3 " *ngIf="confimOtp">

            <button class="btn btn-active" data-bs-dismiss="modal" aria-label="Close" *ngIf="checkoutFlag" type="button"
              data-bs-dismiss="modal" aria-label="Close" (click)="confimOTPCheckout()" [disabled]="showButton" id="logConfirmOTPBtn">
              Confirm OTP
            </button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>



<!-- SignUP -->
<div class="modal fade" id="signupModal" tabindex="-1" aria-labelledby="signupModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h5 class="modal-title text-center section-heading" id="loginModalLabel">Sign Up</h5>
        <form [formGroup]="signupForm">
          <div>
            <label for="email">Email:</label>
            <input type="text" id="email" formControlName="email">
          </div>
          <div>
            <label for="password">Password:</label>
            <input type="password" id="password" formControlName="password">
          </div>
          <div>
            <label for="firstName">First Name:</label>
            <input type="text" id="firstName" formControlName="firstName">
          </div>
          <div>
            <label for="lastName">Last Name:</label>
            <input type="text" id="lastName" formControlName="lastName">
          </div>
          <div>
            <label for="mobileNumber">Mobile Number:</label>
            <input type="text" id="mobileNumber" formControlName="mobileNumber">
          </div>
          <div>
            <button type="submit" data-bs-toggle="modal" data-bs-target="#loginModal">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>