import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PoojaService } from 'src/app/service/pooja.service';
import { CryptojsService } from 'src/app/service/cryptojs.service';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-my-booking',
  templateUrl: './my-booking.component.html',
  styleUrls: ['./my-booking.component.scss']
})
export class MyBookingComponent {
  bookingDetails: any
  bookingDetailsConsultancy: any
  constructor(private poojaService: PoojaService, private toastr: ToastrService,private cryptojs : CryptojsService,private meta: Meta) { }

  ngOnInit(): void {
    const userId = this.cryptojs.decryptLocalStorage();

    if (userId) {
      this.poojaService.getBookingDetails(userId).subscribe(
        (res: any) => {
          this.bookingDetails = res.data;
          const currentDate = new Date(); // Current date and time

          this.bookingDetails.forEach((booking: any) => {
            const poojaDate = new Date(booking.pooja_date);
            if (poojaDate < currentDate) {
              booking.status = "completed";
            } else {
              booking.status = booking.status;
            }
          });
          this.bookingDetails.reverse();
        },
        (error) => {
          console.error('Error fetching booking details:', error);
        }
      );
    } else {
      console.error('UserId is not defined in localStorage');
    }
    this.getconsultancyDetails()
    this.updateMetaTags()

  }

  getconsultancyDetails() {
    const userId = this.cryptojs.decryptLocalStorage();
    if (userId) {
      this.poojaService.getBookingConsultancyDetails(userId).subscribe(
        (res: any) => {
          this.bookingDetailsConsultancy = res.data;
          const currentDate = new Date(); // Current date and time
          this.bookingDetailsConsultancy.forEach((booking: any) => {
            const poojaDate = new Date(booking.pooja_date);
            if (poojaDate < currentDate) {
              booking.status = "completed";
            } else {
              booking.status = "pending";
            }
          });
          this.bookingDetailsConsultancy.reverse();
        },
        (error) => {
          console.error('Error fetching booking details:', error);
        }
      );
    } else {
      console.error('UserId is not defined in localStorage');
    }
  }

  cancelBooking(booking: any) {
    this.poojaService.cancelYajmanPooja(booking.id).subscribe(
      (res: any) => {
        booking.status = "cancel";
        this.toastr.success("Booking Cancelled Successfully")
      },
      (error) => {
        console.error('Error cancelling booking:', error);
      }
    );
  }
  updateMetaTags(){
    this.meta.updateTag(
      { name: 'description', content: '' }, 
    );
    this.meta.updateTag(
      { name: 'keywords', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:title', content: '' }, 
    );
    this.meta.updateTag(
      { property: 'og:determiner', content: '' }, 
    );
    
  }


}
